import axios from 'axios'

import env from '../../config/env'

const { uri: proxy } = env.server

const login = data => {
  return new Promise((resolve, reject) => {
    axios.post(`${proxy}/api/user/login`, data)
      .then(response => resolve(response.data))
      .catch(err => {
        const defaultError = { message: 'Check your internet connection' }
        const { response = { } } = err
        const { data = defaultError } = response
        reject(data)
      })
  })
}

export default { login }
