import { Support as SupportTypes } from '../types'

const {
  REQUEST_FETCH_SUPPORTS,
  FETCH_SUPPORTS_ERROR,
  FETCH_SUPPORTS_SUCCESS,
  RESET_SUPPORT
} = SupportTypes

const initialState = {
  supports: [],
  isLoading: false,
  errors: {
    status: false,
    message: null
  }
}

export default (state = initialState, action) => {
  switch (action.type) {

    case REQUEST_FETCH_SUPPORTS:
      return {
        ...state,
        isLoading: true,
        errors: {
          ...state.errors,
          status: false,
          message: null
        }
      }

    case FETCH_SUPPORTS_SUCCESS:
      return {
        ...state,
        supports: action.payload.supports,
        isLoading: false,
        errors: {
          ...state.errors,
          status: false,
          message: null
        }
      }

    case FETCH_SUPPORTS_ERROR:
      return {
        ...state,
        isLoading: false,
        errors: {
          ...state.errors,
          status: true,
          message: action.payload.message
        }
      }

    case RESET_SUPPORT:
      return {
        ...state,
        isLoading: false,
        errors: {
          ...state.errors,
          status: false,
          message: null
        }
      }

    default:
      return state

  }
}
