import React, { Fragment, useState } from 'react'
import { compose } from 'redux'
import { Link, withRouter } from 'react-router-dom'
import {
  Hidden,
  Drawer,
  SwipeableDrawer,
  IconButton,
  Typography,
  AppBar,
  Toolbar,
  Avatar,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Button
} from '@material-ui/core'
import { Menu as MenuIcon } from '@material-ui/icons'
import { connect } from 'react-redux'
import { logout } from '../store/actions/Auth'
import env from '../config/env'
import AvatarImage from '../assets/images/avatar.jpg'

const drawerContent = (routes, currentRoute, logout) => {
  const homerouter = routes.find((route) => (
    route.path === '/' || route.path === '/admin/dashboard'
  ))

  return (
    (
      <Fragment>
        <div className='profile'>
          <Link to={homerouter.path} className='link'>
            <Avatar src={AvatarImage} className='avatar'/>
          </Link>
          <Link to={homerouter.path} className='link'>
            <div className='name'>{env.profile.nama}</div>
          </Link>
          {
            logout.show
            ? <Fragment>
                <Button
                  variant='outlined'
                  color='secondary'
                  onClick={() => logout.action()}
                  >LOGOUT</Button>
                <br />
              </Fragment>
            : null
          }
        </div>
        <div className='lists'>
          <List component='div'>
            {
              routes.map((route,i) => (
                <Link to={route.path} className='link' key={i}>
                  <ListItem className='list_item' button selected={currentRoute.path === route.path}>
                    <ListItemIcon><route.icon/></ListItemIcon>
                    <ListItemText>{route.name}</ListItemText>
                  </ListItem>
                </Link>
              ))
            }
          </List>
        </div>
      </Fragment>
    )
  )
}

const DrawerComponent = props => {
  const { logout: logoutAction } = props
  let { routes } = props
  const currentRoute = routes.find(route => {
    return route.path === window.location.pathname
  }) || {  }
  routes = routes.filter(route => route.list)
  const [drawerOpen, toggleDrawer] = useState(false)
  const logout = {
    show: props.logoutButton || false,
    action: () => {
      props.history.push('/admin/login')
      logoutAction()
    }
  }
  const content = drawerContent(routes, currentRoute, logout)

  return (
    <Fragment>

      {/* Drawer for desktop */}
      <Hidden smDown>
        <Drawer
          variant='permanent'
          className='drawer'
          classes={{
            paper: 'paper'
          }}
          open
          >
          {content}
        </Drawer>
      </Hidden>

      {/* Drawer for mobile */}
      <Hidden mdUp>
        <AppBar color='secondary' className='appbar'>
          <Toolbar>
            <IconButton
              className='icon'
              edge='start'
              color='inherit'
              aria-label='Menu'
              onClick={() => toggleDrawer(!drawerOpen)}
              >
              <MenuIcon />
            </IconButton>
            <Typography
              className='text'
              variant='h6'>
              {currentRoute.name}
            </Typography>
          </Toolbar>
        </AppBar>
        <SwipeableDrawer
          variant='temporary'
          className='drawer'
          classes={{
            paper: 'paper'
          }}
          open={drawerOpen}
          onClose={() => toggleDrawer(false)}
          onOpen={() => toggleDrawer(true)}
          >
          {content}
        </SwipeableDrawer>
      </Hidden>

    </Fragment>
  )
}

export default compose(
  withRouter,
  connect(null, { logout })
)(DrawerComponent)
