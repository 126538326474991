import axios from 'axios'

import env from '../../config/env'

const { uri: proxy } = env.server

const fetchPapers = () => {
  return new Promise((resolve, reject) => {
    axios.get(`${proxy}/api/paper`)
      .then(response => resolve(response.data))
      .catch(err => {
        const defaultError = { message: 'Check your internet connection' }
        const { response = { } } = err
        const { data = defaultError } = response
        reject(data)
      })
  })
}

const fetchResearchs = () => {
  return new Promise((resolve, reject) => {
    axios.get(`${proxy}/api/research`)
      .then(response => resolve(response.data))
      .catch(err => {
        const defaultError = { message: 'Check your internet connection' }
        const { response = { } } = err
        const { data = defaultError } = response
        reject(data)
      })
  })
}

const fetchBooks = () => {
  return new Promise((resolve, reject) => {
    axios.get(`${proxy}/api/book`)
      .then(response => resolve(response.data))
      .catch(err => {
        const defaultError = { message: 'Check your internet connection' }
        const { response = { } } = err
        const { data = defaultError } = response
        reject(data)
      })
  })
}

const fetchHkis = () => {
  return new Promise((resolve, reject) => {
    axios.get(`${proxy}/api/hki`)
      .then(response => resolve(response.data))
      .catch(err => {
        const defaultError = { message: 'Check your internet connection' }
        const { response = { } } = err
        const { data = defaultError } = response
        reject(data)
      })
  })
}

export default { fetchPapers, fetchResearchs, fetchBooks, fetchHkis }
