import { Paper as PaperTypes } from '../types'

const {
  REQUEST_FETCH_PAPERS,
  FETCH_PAPERS_ERROR,
  FETCH_PAPERS_SUCCESS,
  FETCH_RESEARCHS_SUCCESS,
  FETCH_BOOKS_SUCCESS,
  FETCH_HKIS_SUCCESS,
  RESET_PAPER
} = PaperTypes

const initialState = {
  papers: [],
  researchs: [],
  books: [],
  hkis: [],
  isLoading: false,
  errors: {
    status: false,
    message: null
  }
}

export default (state = initialState, action) => {
  switch (action.type) {

    case REQUEST_FETCH_PAPERS:
      return {
        ...state,
        isLoading: true,
        errors: {
          ...state.errors,
          status: false,
          message: null
        }
      }

    case FETCH_PAPERS_SUCCESS:
      return {
        ...state,
        papers: action.payload.papers,
        isLoading: false,
        errors: {
          ...state.errors,
          status: false,
          message: null
        }
      }

    case FETCH_RESEARCHS_SUCCESS:
      return {
        ...state,
        researchs: action.payload.researchs,
        isLoading: false,
        errors: {
          ...state.errors,
          status: false,
          message: null
        }
      }

    case FETCH_BOOKS_SUCCESS:
      return {
        ...state,
        books: action.payload.books,
        isLoading: false,
        errors: {
          ...state.errors,
          status: false,
          message: null
        }
      }

    case FETCH_HKIS_SUCCESS:
      return {
        ...state,
        hkis: action.payload.hkis,
        isLoading: false,
        errors: {
          ...state.errors,
          status: false,
          message: null
        }
      }

    case FETCH_PAPERS_ERROR:
      return {
        ...state,
        isLoading: false,
        errors: {
          status: true,
          message: action.payload.message
        }
      }

    case RESET_PAPER:
      return {
        ...state,
        isLoading: false,
        errors: {
          ...state.errors,
          status: false,
          message: null
        }
      }

    default:
      return state

  }
}
