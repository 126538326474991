import { put, call } from 'redux-saga/effects'

import { Auth as AuthTypes } from '../types'
import { Auth as AuthApi } from '../api'

const {
  LOGIN_SUCCESS,
  LOGIN_ERROR
} = AuthTypes

export default function* loginWorker({ payload }) {
  try {
    const response = yield call(AuthApi.login, payload)
    yield put({
      type: LOGIN_SUCCESS,
      payload: response
    })
  } catch (err) {
    yield put({
      type: LOGIN_ERROR,
      payload: {
        message: err.message
      }
    })
  }
}
