import React, { Component } from 'react'
import {
  MainTitle,
  TableComponent,
} from '../components'
import env from '../config/env'

class Academic extends Component {
  constructor(props) {
    super(props)
    this.state = {
      academic: env.profile.akademik,
      academicSecond: env.profile.akademikSecond
    }
  }

  render() {
    const title = 'Akademik'
    const { academic, academicSecond } = this.state
    const academicSchema = {
      tahun: 'Tahun',
      tingkat: 'Tingkat',
      jurusan: 'Program Studi',
      universitas: 'Universitas'
    }
    const academicSecondSchema = {
      tahun: 'Tahun',
      tingkat: 'Tingkat',
      jurusan: 'Jurusan',
      nama_sekolah: 'Nama Sekolah'
    }

    return (
      <div>
        <MainTitle title={title}/>
        <TableComponent
          data={academicSecond}
          schema={academicSecondSchema}
          title='Pendidikan'
          expanded/>
        <TableComponent
          data={academic}
          schema={academicSchema}
          title='Pendidikan'
          expanded/>
      </div>
    )
  }
}

export default Academic
