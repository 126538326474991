import { Admin as AdminTypes } from '../../types'

const {
  ADMIN_REQUEST_FETCH_SUPPORTS,
  ADMIN_REQUEST_CREATE_SUPPORT,
  ADMIN_REQUEST_UPDATE_SUPPORT,
  ADMIN_REQUEST_DELETE_SUPPORT,
  ADMIN_RESET_SUPPORT
} = AdminTypes.support

export const fetchSupports = () => {
  return {
    type: ADMIN_REQUEST_FETCH_SUPPORTS
  }
}

export const createSupport = support => {
  return {
    type: ADMIN_REQUEST_CREATE_SUPPORT,
    payload: { support }
  }
}

export const updateSupport = support => {
  return {
    type: ADMIN_REQUEST_UPDATE_SUPPORT,
    payload: { support }
  }
}

export const deleteSupport = id => {
  return {
    type: ADMIN_REQUEST_DELETE_SUPPORT,
    payload: { id }
  }
}

export const resetSupport = () => {
  return {
    type: ADMIN_RESET_SUPPORT
  }
}
