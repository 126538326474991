import React, { Component } from 'react'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import {
  Button,
  IconButton,
  Tooltip,
  TextField,
  Switch
} from '@material-ui/core'
import {
  EditOutlined as EditIcon,
  DeleteOutlined as DeleteIcon,
  CloudUploadOutlined as UploadIcon,
  CreateOutlined as CreateIcon
} from '@material-ui/icons'
import env from '../../config/env'
import {
  MainTitle,
  Snackbar as MySnackbar,
  TableComponent,
  DialogConfirm
} from '../../components'
import {
  fetchPapers,
  createPaper,
  updatePaper,
  deletePaper,
  resetPaper
} from '../../store/actions/admin/Paper'
import {
  fetchResearchs,
  createResearch,
  updateResearch,
  deleteResearch,
  resetResearch
} from '../../store/actions/admin/Research'
import {
  fetchBooks,
  createBook,
  updateBook,
  deleteBook,
  resetBook
} from '../../store/actions/admin/Book'
import {
  fetchHkis,
  createHki,
  updateHki,
  deleteHki,
  resetHki
} from '../../store/actions/admin/HKI'

const { uri: proxy } = env.server

const defaultInput = {
  create: {
    _id: '',
    title: '',
    year: '',
    file: '',
    link: '',
    volume: '',
    proceeding: false
  },
  update: {
    _id: '',
    title: '',
    year: '',
    file: '',
    link: '',
    volume: '',
    proceeding: false
  },
  createResearch: {
    _id: '',
    title: '',
    year: '',
    source: '',
    file: ''
  },
  updateResearch: {
    _id: '',
    title: '',
    year: '',
    source: '',
    file: ''
  },
  createBook: {
    _id: '',
    title: '',
    year: '',
    isbn: '',
    publisher: '',
    link: '',
    file: ''
  },
  updateBook: {
    _id: '',
    title: '',
    year: '',
    isbn: '',
    publisher: '',
    link: '',
    file: ''
  },
  createHki: {
    _id: '',
    title: '',
    type: '',
    year: '',
    registration_number: '',
    status: '',
    number: '',
    link: '',
    file: ''
  },
  updateHki: {
    _id: '',
    title: '',
    type: '',
    year: '',
    registration_number: '',
    status: '',
    number: '',
    link: '',
    file: ''
  }
}

const defaultDialog = {
  delete: {
    attr: null,
    open: false
  },
  create: {
    attr: null,
    open: false
  },
  update: {
    attr: null,
    open: false
  },
  deleteResearch: {
    attr: null,
    open: false
  },
  createResearch: {
    attr: null,
    open: false
  },
  updateResearch: {
    attr: null,
    open: false
  },
  createBook: {
    attr: null,
    open: false
  },
  updateBook: {
    attr: null,
    open: false
  },
  deleteBook: {
    attr: null,
    open: false
  },
  createHki: {
    attr: null,
    open: false
  },
  updateHki: {
    attr: null,
    open: false
  },
  deleteHki: {
    attr: null,
    open: false
  }
}

class Paper extends Component {
  constructor(props) {
    super(props)

    if (props.token === null) {
      props.history.push('/admin/login')
    }

    this.state = {
      input: defaultInput,
      dialog: defaultDialog
    }
  }

  componentDidMount() {
    this.resetError()
    this.props.fetchPapers()
    this.props.fetchResearchs()
    this.props.fetchBooks()
    this.props.fetchHkis()
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.Paper.papers !== nextProps.Paper.papers
      || this.props.Research.researchs !== nextProps.Research.researchs
      || this.props.Book.books !== nextProps.Book.books
      || this.props.HKI.hkis !== nextProps.HKI.hkis
    ) {
      this.setState({
        input: defaultInput,
        dialog: defaultDialog
      })
    }
  }

  toggleDialog = (name, attr = null) => {
    const { state } = this
    this.setState({
      dialog: {
        ...state.dialog,
        [name]: {
          ...state.dialog[name],
          attr: attr,
          open: !state.dialog[name].open
        }
      }
    })
  }

  prepareCreatePaper = () => {
    this.toggleDialog('create')
  }

  createPaper = () => {
    const { create: newPaper } = this.state.input
    const formData = new FormData()
    formData.set('year', newPaper.year)
    formData.set('title', newPaper.title)
    formData.set('file', newPaper.file)
    formData.set('link', newPaper.link)
    formData.set('volume', newPaper.volume)
    formData.set('proceeding', newPaper.proceeding)
    this.props.createPaper(formData)
  }

  prepareUpdatePaper = id => {
    const { papers } = this.props.Paper
    const paperData = papers.find(({ _id }) => _id === id)
    this.setState({
      input: {
        ...this.state.input,
        update: {
          ...paperData,
          file: ''
        }
      }
    }, () => {
      this.toggleDialog('update')
    })
  }

  updatePaper = () => {
    const { update: newPaper } = this.state.input
    const formData = new FormData()
    formData.set('_id', newPaper._id)
    formData.set('year', newPaper.year)
    formData.set('title', newPaper.title)
    formData.set('file', newPaper.file)
    formData.set('link', newPaper.link)
    formData.set('volume', newPaper.volume)
    formData.set('proceeding', newPaper.proceeding)
    this.props.updatePaper(formData)
  }

  prepareDeletePaper = id => {
    this.toggleDialog('delete', id)
  }

  deletePaper = () => {
    const { attr: id } = this.state.dialog.delete
    this.props.deletePaper(id)
  }

  prepareCreateBook = () => {
    this.toggleDialog('createBook')
  }

  createBook = () => {
    const { createBook: newBook } = this.state.input
    const formData = new FormData()
    formData.set('title', newBook.title)
    formData.set('year', newBook.year)
    formData.set('isbn', newBook.isbn)
    formData.set('publisher', newBook.publisher)
    formData.set('link', newBook.link)
    formData.set('file', newBook.file)
    this.props.createBook(formData)
  }

  prepareUpdateBook = id => {
    const { books } = this.props.Book
    const bookData = books.find(({ _id }) => _id === id)
    this.setState({
      input: {
        ...this.state.input,
        updateBook: {
          ...bookData,
          file: ''
        }
      }
    }, () => this.toggleDialog('updateBook'))
  }

  updateBook = () => {
    const { updateBook: newBook } = this.state.input
    const formData = new FormData()
    formData.set('_id', newBook._id)
    formData.set('title', newBook.title)
    formData.set('year', newBook.year)
    formData.set('isbn', newBook.isbn)
    formData.set('publisher', newBook.publisher)
    formData.set('link', newBook.link)
    formData.set('file', newBook.file)
    this.props.updateBook(formData)
  }

  prepareDeleteBook = id => {
    this.toggleDialog('deleteBook', id)
  }

  deleteBook = () => {
    const { attr: id } = this.state.dialog.deleteBook
    this.props.deleteBook(id)
  }

  prepareCreateResearch = () => {
    this.toggleDialog('createResearch')
  }

  createResearch = () => {
    const { createResearch: newResearch } = this.state.input
    const formData = new FormData()
    formData.set('year', newResearch.year)
    formData.set('title', newResearch.title)
    formData.set('file', newResearch.file)
    formData.set('source', newResearch.source)
    this.props.createResearch(formData)
  }

  prepareUpdateResearch = id => {
    const { researchs } = this.props.Research
    const researchData = researchs.find(({ _id }) => _id === id)
    this.setState({
      input: {
        ...this.state.input,
        updateResearch: {
          ...researchData,
          file: ''
        }
      }
    }, () => {
      this.toggleDialog('updateResearch')
    })
  }

  updateResearch = () => {
    const { updateResearch: newResearch } = this.state.input
    const formData = new FormData()
    formData.set('_id', newResearch._id)
    formData.set('year', newResearch.year)
    formData.set('title', newResearch.title)
    formData.set('file', newResearch.file)
    formData.set('source', newResearch.source)
    this.props.updateResearch(formData)
  }

  prepareDeleteResearch = id => {
    this.toggleDialog('deleteResearch', id)
  }

  deleteResearch = () => {
    const { attr: id } = this.state.dialog.deleteResearch
    this.props.deleteResearch(id)
  }

  prepareCreateHki = () => {
    this.toggleDialog('createHki')
  }

  createHki = () => {
    const { createHki: newHki } = this.state.input
    const formData = new FormData()
    formData.set('title', newHki.title)
    formData.set('type', newHki.type)
    formData.set('year', newHki.year)
    formData.set('registration_number', newHki.registration_number)
    formData.set('number', newHki.number)
    formData.set('status', newHki.status)
    formData.set('link', newHki.link)
    formData.set('file', newHki.file)
    this.props.createHki(formData)
  }

  prepareUpdateHki = id => {
    const { hkis } = this.props.HKI
    const hkiData = hkis.find(({ _id }) => _id === id)
    this.setState({
      input: {
        ...this.state.input,
        updateHki: {
          ...hkiData,
          file: ''
        }
      }
    }, () => {
      this.toggleDialog('updateHki')
    })
  }

  updateHki = () => {
    const { updateHki: newHki } = this.state.input
    const formData = new FormData()
    formData.set('_id', newHki._id)
    formData.set('title', newHki.title)
    formData.set('type', newHki.type)
    formData.set('year', newHki.year)
    formData.set('registration_number', newHki.registration_number)
    formData.set('number', newHki.number)
    formData.set('status', newHki.status)
    formData.set('link', newHki.link)
    formData.set('file', newHki.file)
    this.props.updateHki(formData)
  }

  prepareDeleteHki = id => {
    this.toggleDialog('deleteHki', id)
  }

  deleteHki = () => {
    const { attr: id } = this.state.dialog.deleteHki
    this.props.deleteHki(id)
  }

  resetError = () => {
    this.props.resetPaper()
    this.props.resetResearch()
    this.props.resetBook()
    this.props.resetHki()
  }

  onChangeInput = (e, name, fileInput = false) => {
    this.setState({
      input: {
        ...this.state.input,
        [name]: {
          ...this.state.input[name],
          [e.target.id]: fileInput
            ? e.target.files[0] ? e.target.files[0] : this.state.input[e.target.id]
            : e.target.value
        }
      }
    })
  }

  onChangeInputSwitch = (e, name) => {
    this.setState({
      input: {
        ...this.state.input,
        [name]: {
          ...this.state.input[name],
          [e.target.id]: !this.state.input[name][e.target.id]
        }
      }
    })
  }

  render() {
    const {
      onChangeInput,
      onChangeInputSwitch,
      toggleDialog,
      prepareCreatePaper,
      prepareUpdatePaper,
      prepareDeletePaper,
      prepareDeleteResearch,
      prepareCreateResearch,
      prepareUpdateResearch,
      prepareCreateBook,
      prepareUpdateBook,
      prepareDeleteBook,
      prepareCreateHki,
      prepareUpdateHki,
      prepareDeleteHki,
      createPaper,
      updatePaper,
      deletePaper,
      createResearch,
      updateResearch,
      deleteResearch,
      createBook,
      updateBook,
      deleteBook,
      createHki,
      updateHki,
      deleteHki
    } = this
    const { open: deleteDialog } = this.state.dialog.delete
    const { open: createDialog } = this.state.dialog.create
    const { open: updateDialog } = this.state.dialog.update
    const { open: researchDeleteDialog } = this.state.dialog.deleteResearch
    const { open: researchCreateDialog } = this.state.dialog.createResearch
    const { open: researchUpdateDialog } = this.state.dialog.updateResearch
    const { open: bookDeleteDialog } = this.state.dialog.deleteBook
    const { open: bookCreateDialog } = this.state.dialog.createBook
    const { open: bookUpdateDialog } = this.state.dialog.updateBook
    const { open: hkiDeleteDialog } = this.state.dialog.deleteHki
    const { open: hkiCreateDialog } = this.state.dialog.createHki
    const { open: hkiUpdateDialog } = this.state.dialog.updateHki

    const { input } = this.state

    const { isLoading } = this.props.Paper
    let { papers } = this.props.Paper
    papers = papers.map(paper => ({
      ...paper,
      title: paper.link ? (
        <a href={paper.link} target='_blank' rel='noopener noreferrer'>
          {paper.title}
        </a>
      ) : paper.title,
      file: (<Button
        color='secondary'
        variant='outlined'
        target='_blank'
        href={`${proxy}/${paper.file}`}
        >{'UNDUH'}</Button>),
      actions: (
        <div className='actions'>
          <Tooltip title='Update'>
            <IconButton onClick={() => prepareUpdatePaper(paper._id)}>
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title='Delete'>
            <IconButton onClick={() => prepareDeletePaper(paper._id)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </div>
      )
    }))
    const schema = {
      actions: 'Aksi',
      year: 'Tahun',
      title: 'Judul',
      file: 'Berkas'
    }

    const all = papers
    papers = all.filter(paper => !paper.proceeding)
    let proceedings = all.filter(paper => paper.proceeding)

    let { researchs } = this.props.Research
    researchs = researchs.map(research => ({
      ...research,
      file: (<Button
        color='secondary'
        variant='outlined'
        target='_blank'
        href={`${proxy}/${research.file}`}
        >{'UNDUH'}</Button>),
      actions: (
        <div className='actions'>
          <Tooltip title='Update'>
            <IconButton onClick={() => prepareUpdateResearch(research._id)}>
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title='Delete'>
            <IconButton onClick={() => prepareDeleteResearch(research._id)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </div>
      )
    }))
    const researchSchema = {
      actions: 'Aksi',
      year: 'Tahun',
      title: 'Judul',
      source: 'Sumber',
      file: 'Berkas'
    }

    const { isLoading: researchIsLoading } = this.props.Research
    const { isLoading: bookIsLoading } = this.props.Book
    const { isLoading: hkiIsLoading } = this.props.HKI

    let { books } = this.props.Book
    books = books.map(book => ({
      ...book,
      title: book.link ? (
        <a href={book.link} target='_blank' rel='noopener noreferrer'>
          {book.title}
        </a>
      ) : book.title,
      file: (<Button
        color='secondary'
        variant='outlined'
        target='_blank'
        href={`${proxy}/${book.file}`}
        >{'UNDUH'}</Button>),
      actions: (
        <div className='actions'>
          <Tooltip title='Update'>
            <IconButton onClick={() => prepareUpdateBook(book._id)}>
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title='Delete'>
            <IconButton onClick={() => prepareDeleteBook(book._id)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </div>
      )
    }))
    const bookSchema = {
      actions: 'Aksi',
      title: 'Judul',
      year: 'Tahun',
      isbn: 'ISBN',
      publisher: 'Penerbit',
      file: 'Berkas'
    }

    let { hkis } = this.props.HKI
    hkis = hkis.map(hki => ({
      ...hki,
      title: hki.link ? (
        <a href={hki.link} target='_blank' rel='noopener noreferrer'>
          {hki.title}
        </a>
      ) : hki.title,
      file: (<Button
        color='secondary'
        variant='outlined'
        target='_blank'
        href={`${proxy}/${hki.file}`}
        >{'UNDUH'}</Button>),
      actions: (
        <div className='actions'>
          <Tooltip title='Update'>
            <IconButton onClick={() => prepareUpdateHki(hki._id)}>
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title='Delete'>
            <IconButton onClick={() => prepareDeleteHki(hki._id)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </div>
      )
    }))
    const hkiSchema = {
      actions: 'Aksi',
      title: 'Judul',
      type: 'Jenis',
      year: 'Tahun',
      registration_number: 'No. Pendaftaran',
      status: 'Status',
      number: 'No. HKI',
      file: 'Berkas'
    }

    const { errors: snackbar_error_1 } = this.props.Paper
    const { errors: snackbar_error_2 } = this.props.Research
    const { errors: snackbar_error_3 } = this.props.Book
    const { errors: snackbar_error_4 } = this.props.HKI

    const errors = snackbar_error_1.status ? snackbar_error_1
      : (snackbar_error_2.status ? snackbar_error_2
      : (snackbar_error_3.status ? snackbar_error_3
      : snackbar_error_4))

    return (
      <div>
        <MainTitle title={'Research'}/>
        <TableComponent
          loading={isLoading}
          title={'Journal'}
          data={papers}
          schema={schema}
          headerNode={(
            <Tooltip title='Create'>
              <Button
                variant='outlined'
                color='secondary'
                onClick={() => prepareCreatePaper()}>
                <CreateIcon fontSize='small'/>&nbsp;
                Tambah
              </Button>
            </Tooltip>
          )}
          />
        <TableComponent
          loading={isLoading}
          title={'Proceeding'}
          data={proceedings}
          schema={schema}
          headerNode={(
            <Tooltip title='Create'>
              <Button
                variant='outlined'
                color='secondary'
                onClick={() => prepareCreatePaper()}>
                <CreateIcon fontSize='small'/>&nbsp;
                Tambah
              </Button>
            </Tooltip>
          )}
          />
        <TableComponent
          loading={researchIsLoading}
          title={'Penelitian'}
          data={researchs}
          schema={researchSchema}
          headerNode={(
            <Tooltip title='Create'>
              <Button
                variant='outlined'
                color='secondary'
                onClick={() => prepareCreateResearch()}>
                <CreateIcon fontSize='small'/>&nbsp;
                Tambah
              </Button>
            </Tooltip>
          )}
          />
        <TableComponent
          loading={bookIsLoading}
          title={'Buku'}
          data={books}
          schema={bookSchema}
          headerNode={(
            <Tooltip title='Create'>
              <Button
                variant='outlined'
                color='secondary'
                onClick={() => prepareCreateBook()}>
                <CreateIcon fontSize='small'/>&nbsp;
                Tambah
              </Button>
            </Tooltip>
          )}
          />
        <TableComponent
          loading={hkiIsLoading}
          title={'HKI'}
          data={hkis}
          schema={hkiSchema}
          headerNode={(
            <Tooltip title='Create'>
              <Button
                variant='outlined'
                color='secondary'
                onClick={() => prepareCreateHki()}>
                <CreateIcon fontSize='small'/>&nbsp;
                Tambah
              </Button>
            </Tooltip>
          )}
          />
        <DialogConfirm
          open={bookDeleteDialog}
          title={'Hapus'}
          body={'Apakah anda yakin menghapus Buku ini?'}
          onConfirm={() => deleteBook()}
          onClose={() => toggleDialog('deleteBook')}
          />
        <DialogConfirm
          open={hkiDeleteDialog}
          title={'Hapus'}
          body={'Apakah anda yakin menghapus HKI ini?'}
          onConfirm={() => deleteHki()}
          onClose={() => toggleDialog('deleteHki')}
          />
        <DialogConfirm
          open={researchDeleteDialog}
          title={'Hapus'}
          body={'Apakah anda yakin menghapus Penelitian ini?'}
          onConfirm={() => deleteResearch()}
          onClose={() => toggleDialog('deleteResearch')}
          />
        <DialogConfirm
          open={deleteDialog}
          title={'Hapus'}
          body={'Apakah anda yakin menghapus Paper ini?'}
          onConfirm={() => deletePaper()}
          onClose={() => toggleDialog('delete')}
          />
        <DialogConfirm
          open={createDialog}
          title={'Tambah'}
          body={'Masukkan beberapa informasi Publikasi Ilmiah anda dibawah'}
          onConfirm={() => createPaper()}
          onClose={() => toggleDialog('create')}
          inputNode={(
            <form className='dialog-form'>
              <Switch
                id='proceeding'
                checked={input.create.proceeding}
                onChange={e => onChangeInputSwitch(e, 'create')}
                value='Proceeding'
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              /><span className='swith-text'>Proceeding</span>
              <br /><br />
              <TextField
                id='title'
                margin='dense'
                label='Judul'
                value={input.create.title}
                onChange={e => onChangeInput(e, 'create')}
                variant='outlined'
                fullWidth
              />
              <TextField
                id='link'
                margin='normal'
                label='Link'
                value={input.create.link}
                onChange={e => onChangeInput(e, 'create')}
                variant='outlined'
                fullWidth
              />
              <TextField
                id='volume'
                margin='normal'
                label='Volume'
                value={input.create.volume}
                onChange={e => onChangeInput(e, 'create')}
                variant='outlined'
                fullWidth
              />
              <TextField
                id='year'
                margin='normal'
                label='Tahun'
                value={input.create.year}
                onChange={e => onChangeInput(e, 'create')}
                variant='outlined'
                fullWidth
              />
            <br /><br />
            <div className='input-file'>
              <input
                id='file'
                type='file'
                style={{ display: 'none' }}
                onChange={e => onChangeInput(e, 'create', true)}
                accept='application/pdf'
                />
              <label htmlFor='file'>
                <Tooltip title='Upload file'>
                  <IconButton color='secondary' component='span'>
                    <UploadIcon />
                  </IconButton>
                </Tooltip>
              </label>
              <TextField
                disabled
                placeholder={input.create.file.name}
                fullWidth
                className='file-name-input'
                />
            </div>
            </form>
          )}
          />
        <DialogConfirm
          open={updateDialog}
          title={'Perbaruan'}
          body={'Masukkan perbaruan informasi Publikasi Ilmiah anda dibawah'}
          onConfirm={() => updatePaper()}
          onClose={() => toggleDialog('update')}
          inputNode={(
            <form className='dialog-form'>
              <Switch
                id='proceeding'
                checked={input.update.proceeding}
                onChange={e => onChangeInputSwitch(e, 'update')}
                value='Proceeding'
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              /><span className='swith-text'>Proceeding</span>
              <br /><br />
              <TextField
                id='title'
                margin='dense'
                label='Judul'
                value={input.update.title}
                onChange={e => onChangeInput(e, 'update')}
                variant='outlined'
                fullWidth
              />
              <TextField
                id='link'
                margin='normal'
                label='Link'
                value={input.update.link}
                onChange={e => onChangeInput(e, 'update')}
                variant='outlined'
                fullWidth
              />
              <TextField
                id='volume'
                margin='normal'
                label='Volume'
                value={input.update.volume}
                onChange={e => onChangeInput(e, 'update')}
                variant='outlined'
                fullWidth
              />
              <TextField
                id='year'
                margin='normal'
                label='Tahun'
                value={input.update.year}
                onChange={e => onChangeInput(e, 'update')}
                variant='outlined'
                fullWidth
              />
              <br /><br />
              <div className='input-file'>
                <input
                  id='file'
                  type='file'
                  style={{ display: 'none' }}
                  onChange={e => onChangeInput(e, 'update', true)}
                  />
                <label htmlFor='file'>
                  <Tooltip title='Upload file'>
                    <IconButton color='secondary' component='span'>
                      <UploadIcon />
                    </IconButton>
                  </Tooltip>
                </label>
                <TextField disabled placeholder={input.update.file.name} fullWidth  className='file-name-input'/>
              </div>
            </form>
          )}
          />
        <DialogConfirm
          open={researchCreateDialog}
          title={'Tambah'}
          body={'Masukkan beberapa informasi Penelitian Ilmiah anda dibawah'}
          onConfirm={() => createResearch()}
          onClose={() => toggleDialog('createResearch')}
          inputNode={(
            <form className='dialog-form'>
              <TextField
                id='title'
                margin='dense'
                label='Judul'
                value={input.createResearch.title}
                onChange={e => onChangeInput(e, 'createResearch')}
                variant='outlined'
                fullWidth
              />
              <TextField
                id='source'
                margin='normal'
                label='Sumber'
                value={input.createResearch.source}
                onChange={e => onChangeInput(e, 'createResearch')}
                variant='outlined'
                fullWidth
              />
              <TextField
                id='year'
                margin='normal'
                label='Tahun'
                value={input.createResearch.year}
                onChange={e => onChangeInput(e, 'createResearch')}
                variant='outlined'
                fullWidth
              />
            <br /><br />
            <div className='input-file'>
              <input
                id='file'
                type='file'
                style={{ display: 'none' }}
                onChange={e => onChangeInput(e, 'createResearch', true)}
                accept='application/pdf'
                />
              <label htmlFor='file'>
                <Tooltip title='Upload file'>
                  <IconButton color='secondary' component='span'>
                    <UploadIcon />
                  </IconButton>
                </Tooltip>
              </label>
              <TextField
                disabled
                placeholder={input.createResearch.file.name}
                fullWidth
                className='file-name-input'
                />
            </div>
            </form>
          )}
          />
        <DialogConfirm
          open={researchUpdateDialog}
          title={'Perbaruan'}
          body={'Masukkan perbaruan informasi Penelitian Ilmiah anda dibawah'}
          onConfirm={() => updateResearch()}
          onClose={() => toggleDialog('updateResearch')}
          inputNode={(
            <form className='dialog-form'>
              <TextField
                id='title'
                margin='dense'
                label='Judul'
                value={input.updateResearch.title}
                onChange={e => onChangeInput(e, 'updateResearch')}
                variant='outlined'
                fullWidth
              />
              <TextField
                id='source'
                margin='normal'
                label='Sumber'
                value={input.updateResearch.source}
                onChange={e => onChangeInput(e, 'updateResearch')}
                variant='outlined'
                fullWidth
              />
              <TextField
                id='year'
                margin='normal'
                label='Tahun'
                value={input.updateResearch.year}
                onChange={e => onChangeInput(e, 'updateResearch')}
                variant='outlined'
                fullWidth
              />
            <br /><br />
            <div className='input-file'>
              <input
                id='file'
                type='file'
                style={{ display: 'none' }}
                onChange={e => onChangeInput(e, 'updateResearch', true)}
                accept='application/pdf'
                />
              <label htmlFor='file'>
                <Tooltip title='Upload file'>
                  <IconButton color='secondary' component='span'>
                    <UploadIcon />
                  </IconButton>
                </Tooltip>
              </label>
              <TextField
                disabled
                placeholder={input.updateResearch.file.name}
                fullWidth
                className='file-name-input'
                />
            </div>
            </form>
          )}
          />
          <DialogConfirm
            open={bookCreateDialog}
            title={'Tambah'}
            body={'Masukkan beberapa informasi Buku anda dibawah'}
            onConfirm={() => createBook()}
            onClose={() => toggleDialog('createBook')}
            inputNode={(
              <form className='dialog-form'>
                <TextField
                  id='title'
                  margin='dense'
                  label='Judul'
                  value={input.createBook.title}
                  onChange={e => onChangeInput(e, 'createBook')}
                  variant='outlined'
                  fullWidth
                />
                <TextField
                  id='year'
                  margin='dense'
                  label='Tahun'
                  value={input.createBook.year}
                  onChange={e => onChangeInput(e, 'createBook')}
                  variant='outlined'
                  fullWidth
                />
                <TextField
                  id='isbn'
                  margin='dense'
                  label='ISBN'
                  value={input.createBook.isbn}
                  onChange={e => onChangeInput(e, 'createBook')}
                  variant='outlined'
                  fullWidth
                />
                <TextField
                  id='publisher'
                  margin='dense'
                  label='Penerbit'
                  value={input.createBook.publisher}
                  onChange={e => onChangeInput(e, 'createBook')}
                  variant='outlined'
                  fullWidth
                />
                <TextField
                  id='link'
                  margin='dense'
                  label='Link'
                  value={input.createBook.link}
                  onChange={e => onChangeInput(e, 'createBook')}
                  variant='outlined'
                  fullWidth
                />
              <br /><br />
              <div className='input-file'>
                <input
                  id='file'
                  type='file'
                  style={{ display: 'none' }}
                  onChange={e => onChangeInput(e, 'createBook', true)}
                  accept='application/pdf'
                  />
                <label htmlFor='file'>
                  <Tooltip title='Upload file'>
                    <IconButton color='secondary' component='span'>
                      <UploadIcon />
                    </IconButton>
                  </Tooltip>
                </label>
                <TextField
                  disabled
                  placeholder={input.createBook.file.name}
                  fullWidth
                  className='file-name-input'
                  />
              </div>
              </form>
            )}
            />
          <DialogConfirm
            open={bookUpdateDialog}
            title={'Tambah'}
            body={'Masukkan perbaruan informasi Buku anda dibawah'}
            onConfirm={() => updateBook()}
            onClose={() => toggleDialog('updateBook')}
            inputNode={(
              <form className='dialog-form'>
                <TextField
                  id='title'
                  margin='dense'
                  label='Judul'
                  value={input.updateBook.title}
                  onChange={e => onChangeInput(e, 'updateBook')}
                  variant='outlined'
                  fullWidth
                />
                <TextField
                  id='year'
                  margin='dense'
                  label='Tahun'
                  value={input.updateBook.year}
                  onChange={e => onChangeInput(e, 'updateBook')}
                  variant='outlined'
                  fullWidth
                />
                <TextField
                  id='isbn'
                  margin='dense'
                  label='ISBN'
                  value={input.updateBook.isbn}
                  onChange={e => onChangeInput(e, 'updateBook')}
                  variant='outlined'
                  fullWidth
                />
                <TextField
                  id='publisher'
                  margin='dense'
                  label='Penerbit'
                  value={input.updateBook.publisher}
                  onChange={e => onChangeInput(e, 'updateBook')}
                  variant='outlined'
                  fullWidth
                />
                <TextField
                  id='link'
                  margin='dense'
                  label='Link'
                  value={input.updateBook.link}
                  onChange={e => onChangeInput(e, 'updateBook')}
                  variant='outlined'
                  fullWidth
                />
              <br /><br />
              <div className='input-file'>
                <input
                  id='file'
                  type='file'
                  style={{ display: 'none' }}
                  onChange={e => onChangeInput(e, 'updateBook', true)}
                  accept='application/pdf'
                  />
                <label htmlFor='file'>
                  <Tooltip title='Upload file'>
                    <IconButton color='secondary' component='span'>
                      <UploadIcon />
                    </IconButton>
                  </Tooltip>
                </label>
                <TextField
                  disabled
                  placeholder={input.updateBook.file.name}
                  fullWidth
                  className='file-name-input'
                  />
              </div>
              </form>
            )}
            />
          <DialogConfirm
            open={hkiCreateDialog}
            title={'Tambah'}
            body={'Masukkan beberapa informasi HKI anda dibawah'}
            onConfirm={() => createHki()}
            onClose={() => toggleDialog('createHki')}
            inputNode={(
              <form className='dialog-form'>
                <TextField
                  id='title'
                  margin='dense'
                  label='Judul'
                  value={input.createHki.title}
                  onChange={e => onChangeInput(e, 'createHki')}
                  variant='outlined'
                  fullWidth
                />
                <TextField
                  id='type'
                  margin='dense'
                  label='Jenis'
                  value={input.createHki.type}
                  onChange={e => onChangeInput(e, 'createHki')}
                  variant='outlined'
                  fullWidth
                />
                <TextField
                  id='year'
                  margin='dense'
                  label='Tahun'
                  value={input.createHki.year}
                  onChange={e => onChangeInput(e, 'createHki')}
                  variant='outlined'
                  fullWidth
                />
                <TextField
                  id='registration_number'
                  margin='dense'
                  label='No. Pendaftaran'
                  value={input.createHki.registration_number}
                  onChange={e => onChangeInput(e, 'createHki')}
                  variant='outlined'
                  fullWidth
                />
                <TextField
                  id='status'
                  margin='dense'
                  label='Status'
                  value={input.createHki.status}
                  onChange={e => onChangeInput(e, 'createHki')}
                  variant='outlined'
                  fullWidth
                />
                <TextField
                  id='number'
                  margin='dense'
                  label='No. HKI'
                  value={input.createHki.number}
                  onChange={e => onChangeInput(e, 'createHki')}
                  variant='outlined'
                  fullWidth
                />
                <TextField
                  id='link'
                  margin='dense'
                  label='Link'
                  value={input.createHki.link}
                  onChange={e => onChangeInput(e, 'createHki')}
                  variant='outlined'
                  fullWidth
                />
              <br /><br />
              <div className='input-file'>
                <input
                  id='file'
                  type='file'
                  style={{ display: 'none' }}
                  onChange={e => onChangeInput(e, 'createHki', true)}
                  accept='application/pdf'
                  />
                <label htmlFor='file'>
                  <Tooltip title='Upload file'>
                    <IconButton color='secondary' component='span'>
                      <UploadIcon />
                    </IconButton>
                  </Tooltip>
                </label>
                <TextField
                  disabled
                  placeholder={input.createHki.file.name}
                  fullWidth
                  className='file-name-input'
                  />
              </div>
              </form>
            )}
            />
          <DialogConfirm
            open={hkiUpdateDialog}
            title={'Tambah'}
            body={'Masukkan perbaruan informasi PHKI anda dibawah'}
            onConfirm={() => updateHki()}
            onClose={() => toggleDialog('updateHki')}
            inputNode={(
              <form className='dialog-form'>
                <TextField
                  id='title'
                  margin='dense'
                  label='Judul'
                  value={input.updateHki.title}
                  onChange={e => onChangeInput(e, 'updateHki')}
                  variant='outlined'
                  fullWidth
                />
                <TextField
                  id='type'
                  margin='dense'
                  label='Jenis'
                  value={input.updateHki.type}
                  onChange={e => onChangeInput(e, 'updateHki')}
                  variant='outlined'
                  fullWidth
                />
                <TextField
                  id='year'
                  margin='dense'
                  label='Tahun'
                  value={input.updateHki.year}
                  onChange={e => onChangeInput(e, 'updateHki')}
                  variant='outlined'
                  fullWidth
                />
                <TextField
                  id='registration_number'
                  margin='dense'
                  label='No. Pendaftaran'
                  value={input.updateHki.registration_number}
                  onChange={e => onChangeInput(e, 'updateHki')}
                  variant='outlined'
                  fullWidth
                />
                <TextField
                  id='status'
                  margin='dense'
                  label='Status'
                  value={input.updateHki.status}
                  onChange={e => onChangeInput(e, 'updateHki')}
                  variant='outlined'
                  fullWidth
                />
                <TextField
                  id='number'
                  margin='dense'
                  label='No. HKI'
                  value={input.updateHki.number}
                  onChange={e => onChangeInput(e, 'updateHki')}
                  variant='outlined'
                  fullWidth
                />
                <TextField
                  id='link'
                  margin='dense'
                  label='Link'
                  value={input.updateHki.link}
                  onChange={e => onChangeInput(e, 'updateHki')}
                  variant='outlined'
                  fullWidth
                />
              <br /><br />
              <div className='input-file'>
                <input
                  id='file'
                  type='file'
                  style={{ display: 'none' }}
                  onChange={e => onChangeInput(e, 'updateHki', true)}
                  accept='application/pdf'
                  />
                <label htmlFor='file'>
                  <Tooltip title='Upload file'>
                    <IconButton color='secondary' component='span'>
                      <UploadIcon />
                    </IconButton>
                  </Tooltip>
                </label>
                <TextField
                  disabled
                  placeholder={input.updateHki.file.name}
                  fullWidth
                  className='file-name-input'
                  />
              </div>
              </form>
            )}
            />
          <MySnackbar
            variant={'error'}
            open={errors.status}
            message={errors.message}
            handleClose={this.resetError}
            />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  token: state.Auth.token,
  Paper: state.AdminPaper,
  Research: state.AdminResearch,
  Book: state.AdminBook,
  HKI: state.AdminHKI
})

export default compose(
  connect(mapStateToProps, {
    fetchPapers,
    createPaper,
    updatePaper,
    deletePaper,
    resetPaper,
    fetchResearchs,
    createResearch,
    updateResearch,
    deleteResearch,
    resetResearch,
    fetchBooks,
    createBook,
    updateBook,
    deleteBook,
    resetBook,
    fetchHkis,
    createHki,
    updateHki,
    deleteHki,
    resetHki
  }),
  withRouter
)(Paper)
