import axios from 'axios'

import env from '../../../config/env'
import { store } from '../../index'
import generateHeaderAuth from '../../../utils/generate-authorization-header'

const { uri: proxy } = env.server
const articleApiUri = `${proxy}/api/article`

const fetchArticles = () => {
  return new Promise((resolve, reject) => {
    axios.get(`${articleApiUri}`)
      .then(response => resolve(response.data))
      .catch(err => {
        const defaultError = { message: 'Check your internet connection' }
        const { response = { } } = err
        const { data = defaultError } = response
        reject(data)
      })
  })
}

const createArticle = article => {
  const { token } = store.getState().Auth
  return new Promise((resolve, reject) => {
    axios.post(`${articleApiUri}`, article, generateHeaderAuth(token))
      .then(resolve)
      .catch(err => {
        const defaultError = { message: 'Check your internet connection' }
        const { response = { } } = err
        const { data = defaultError } = response
        reject(data)
      })
  })
}

const updateArticle = article => {
  const { token } = store.getState().Auth
  const id = article.get('_id')
  return new Promise((resolve, reject) => {
    axios.put(`${articleApiUri}/${id}`, article, generateHeaderAuth(token))
      .then(resolve)
      .catch(err => {
        const defaultError = { message: 'Check your internet connection' }
        const { response = { } } = err
        const { data = defaultError } = response
        reject(data)
      })
  })
}

const deleteArticle = id => {
  const { token } = store.getState().Auth
  return new Promise((resolve, reject) => {
    axios.delete(`${articleApiUri}/${id}`, { ...generateHeaderAuth(token) })
      .then(resolve)
      .catch(err => {
        const defaultError = { message: 'Check your internet connection' }
        const { response = { } } = err
        const { data = defaultError } = response
        reject(data)
      })
  })
}

export default { fetchArticles, createArticle, updateArticle, deleteArticle }
