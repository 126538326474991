import { Admin as AdminTypes } from '../../types'

const {
  ADMIN_REQUEST_FETCH_RESEARCHS,
  ADMIN_REQUEST_CREATE_RESEARCH,
  ADMIN_REQUEST_UPDATE_RESEARCH,
  ADMIN_REQUEST_DELETE_RESEARCH,
  ADMIN_RESET_RESEARCH
} = AdminTypes.research

export const fetchResearchs = () => {
  return {
    type: ADMIN_REQUEST_FETCH_RESEARCHS
  }
}

export const createResearch = research => {
  return {
    type: ADMIN_REQUEST_CREATE_RESEARCH,
    payload: { research }
  }
}

export const updateResearch = research => {
  return {
    type: ADMIN_REQUEST_UPDATE_RESEARCH,
    payload: { research }
  }
}

export const deleteResearch = id => {
  return {
    type: ADMIN_REQUEST_DELETE_RESEARCH,
    payload: { id }
  }
}

export const resetResearch = () => {
  return {
    type: ADMIN_RESET_RESEARCH
  }
}
