import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  Button
} from '@material-ui/core'
import {
  MainTitle,
  TableComponent,
} from '../components'
import { fetchPapers, resetPaper } from '../store/actions/Paper'
import env from '../config/env'
import { Snackbar as MySnackbar } from '../components'

const { uri: proxy } = env.server

class Research extends Component {
  componentDidMount() {
    this.props.fetchPapers()
    this.resetError()
  }

  resetError = () => {
    this.props.resetPaper()
  }

  render() {
    let { papers, researchs, books, hkis } = this.props.Paper
    let { isLoading, errors } = this.props.Paper

    papers = papers.map(paper => ({
      ...paper,
      title: paper.link ? (
        <a href={paper.link} target='_blank' rel='noopener noreferrer'>
          {paper.title}
        </a>
      ) : paper.title,
      file: (<Button
        color='secondary'
        variant='outlined'
        target='_blank'
        href={`${proxy}/${paper.file}`}
        >{'UNDUH'}</Button>)
    }))
    const schema = {
      year: 'Tahun',
      title: 'Judul',
      file: 'Berkas'
    }

    researchs = researchs.map(research => ({
      ...research,
      file: (<Button
        color='secondary'
        variant='outlined'
        target='_blank'
        href={`${proxy}/${research.file}`}
        >{'UNDUH'}</Button>)
    }))
    const researchSchema = {
      year: 'Tahun',
      title: 'Judul',
      source: 'Sumber',
      file: 'Berkas'
    }

    books = books.map(book => ({
      ...book,
      title: book.link ? (
        <a href={book.link} target='_blank' rel='noopener noreferrer'>
          {book.title}
        </a>
      ) : book.title,
      file: (<Button
        color='secondary'
        variant='outlined'
        target='_blank'
        href={`${proxy}/${book.file}`}
        >{'UNDUH'}</Button>)
    }))
    const bookSchema = {
      year: 'Tahun',
      title: 'Judul',
      isbn: 'ISBN',
      publisher: 'Penerbit',
      file: 'Berkas'
    }

    hkis = hkis.map(hki => ({
      ...hki,
      title: hki.link ? (
        <a href={hki.link} target='_blank' rel='noopener noreferrer'>
          {hki.title}
        </a>
      ) : hki.title,
      file: (<Button
        color='secondary'
        variant='outlined'
        target='_blank'
        href={`${proxy}/${hki.file}`}
        >{'UNDUH'}</Button>)
    }))
    const hkiSchema = {
      year: 'Tahun',
      title: 'Judul',
      type: 'Jenis',
      registration_number: 'No. Pendaftaran',
      status: 'Status',
      number: 'No. HKI',
      file: 'Berkas'
    }

    const title = 'Penelitian'
    const all = papers
    papers = all.filter(paper => !paper.proceeding)
    let proceedings = all.filter(paper => paper.proceeding)

    return (
      <div>
        <MySnackbar
          open={errors.status}
          message={errors.message}
          variant={'error'}
          handleClose={this.resetError}
          />
        <MainTitle title={title}/>
        <TableComponent
          loading={isLoading}
          data={papers}
          schema={schema}
          title={'Journal'}
          />
        <TableComponent
          loading={isLoading}
          data={proceedings}
          schema={schema}
          title={'Proceeding'}
          />
        <TableComponent
          loading={isLoading}
          data={researchs}
          schema={researchSchema}
          title={'Penelitian'}
          />
        <TableComponent
          loading={isLoading}
          data={books}
          schema={bookSchema}
          title={'Buku'}
          />
        <TableComponent
          loading={isLoading}
          data={hkis}
          schema={hkiSchema}
          title={'Hak Kekayaan Intelektual'}
          />
      </div>
    )
  }
}

const mapStateToProps = ({ Paper }) => ({
  Paper: Paper
})

export default connect(mapStateToProps, { fetchPapers, resetPaper })(Research)
