import React from 'react'
import PropTypes from 'prop-types'
import {
  Snackbar,
  SnackbarContent,
  IconButton
} from '@material-ui/core'
import {
  CheckCircle as SuccessIcon,
  Error as ErrorIcon,
  Info as InfoIcon,
  Warning as WarningIcon,
  Close as CloseIcon
} from '@material-ui/icons'

const SnackbarComponent = props => {
  const { open, message, variant, handleClose: close } = props
  const isOpen = open

  const VariantIcon = {
    success: SuccessIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') return
    close()
  }

  const Icon = VariantIcon[variant]

  return (
    <Snackbar
      className='snackbar'
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      open={isOpen}
      autoHideDuration={3000}
      onClose={handleClose}>
      <SnackbarContent
        className={`variant ${variant}`}
        message={
          <span className='message'>
            <Icon className={`icon`}/>
            {message}
          </span>
        }
        action={
          <IconButton onClick={handleClose} color='inherit'>
            <CloseIcon className='icon'/>
          </IconButton>
        }/>
    </Snackbar>
  )
}

SnackbarComponent.propTypes = {
  open: PropTypes.bool,
  message: PropTypes.string,
  variant: PropTypes.oneOf(['success', 'warning', 'error', 'info'])
}

export default SnackbarComponent
