import { call, put } from 'redux-saga/effects'

import { Admin as AdminTypes } from '../../types'
import { Community as CommunityApi } from '../../api/admin'

const {
  ADMIN_REQUEST_FETCH_COMMUNITYS,
  ADMIN_ACTION_COMMUNITY_SUCCESS,
  ADMIN_ACTION_COMMUNITY_ERROR
} = AdminTypes.community

function* fetchCommunitys() {
  try {
    const response = yield call(CommunityApi.fetchCommunitys)
    yield put({
      type: ADMIN_ACTION_COMMUNITY_SUCCESS,
      payload: {
        communitys: response.data
      }
    })
  } catch (err) {
    yield put({
      type: ADMIN_ACTION_COMMUNITY_ERROR,
      payload: {
        message: err.message
      }
    })
  }
}

function* createCommunity({ payload }) {
  try {
    yield call(CommunityApi.createCommunity, payload.community)
    yield put({
      type: ADMIN_REQUEST_FETCH_COMMUNITYS
    })
  } catch (err) {
    yield put({
      type: ADMIN_ACTION_COMMUNITY_ERROR,
      payload: {
        message: err.message
      }
    })
  }
}

function* updateCommunity({ payload }) {
  try {
    yield call(CommunityApi.updateCommunity, payload.community)
    yield put({
      type: ADMIN_REQUEST_FETCH_COMMUNITYS
    })
  } catch (err) {
    yield put({
      type: ADMIN_ACTION_COMMUNITY_ERROR,
      payload: {
        message: err.message
      }
    })
  }
}

function* deleteCommunity({ payload }) {
  try {
    yield call(CommunityApi.deleteCommunity, payload.id)
    yield put({
      type: ADMIN_REQUEST_FETCH_COMMUNITYS
    })
  } catch (err) {
    yield put({
      type: ADMIN_ACTION_COMMUNITY_ERROR,
      payload: {
        message: err.message
      }
    })
  }
}

export default { fetchCommunitys, createCommunity, updateCommunity, deleteCommunity }
