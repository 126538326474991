import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  Button
} from '@material-ui/core'
import {
  MainTitle,
  TableComponent,
} from '../components'
import { fetchCommunitys, resetCommunity } from '../store/actions/Community'
import env from '../config/env'
import { Snackbar as MySnackbar } from '../components'

const { uri: proxy } = env.server

class Community extends Component {
  componentDidMount() {
    this.props.fetchCommunitys()
    this.resetError()
  }

  resetError = () => {
    this.props.resetCommunity()
  }

  render() {
    let { communitys } = this.props.Community
    const { isLoading, errors } = this.props.Community

    communitys = communitys.map(community => ({
      ...community,
      file: (<Button
        color='secondary'
        variant='outlined'
        target='_blank'
        href={`${proxy}/${community.file}`}
        >{'UNDUH'}</Button>)
    }))

    const schema = {
      year: 'Tahun',
      title: 'Judul',
      source: 'Sumber',
      place: 'Tempat',
      file: 'Berkas'
    }

    return (
      <div>
        <MySnackbar
          open={errors.status}
          message={errors.message}
          variant={'error'}
          handleClose={this.resetError}
          />
        <MainTitle title='Pengabdian Masyarakat'/>
        <TableComponent
          loading={isLoading}
          data={communitys}
          schema={schema}
          title={'Pengabdian Masyarakat'}
          />
      </div>
    )
  }
}

const mapStateToProps = ({ Community }) => ({
  Community: Community
})

export default connect(mapStateToProps, { fetchCommunitys, resetCommunity })(Community)
