import axios from 'axios'

import env from '../../../config/env'
import { store } from '../../index'
import generateHeaderAuth from '../../../utils/generate-authorization-header'

const { uri: proxy } = env.server
const researchApiUri = `${proxy}/api/research`

const fetchResearchs = () => {
  return new Promise((resolve, reject) => {
    axios.get(`${researchApiUri}`)
      .then(response => resolve(response.data))
      .catch(err => {
        const defaultError = { message: 'Check your internet connection' }
        const { response = { } } = err
        const { data = defaultError } = response
        reject(data)
      })
  })
}

const createResearch = research => {
  const { token } = store.getState().Auth
  return new Promise((resolve, reject) => {
    axios.post(`${researchApiUri}`, research, generateHeaderAuth(token))
      .then(resolve)
      .catch(err => {
        const defaultError = { message: 'Check your internet connection' }
        const { response = { } } = err
        const { data = defaultError } = response
        reject(data)
      })
  })
}

const updateResearch = research => {
  const { token } = store.getState().Auth
  const id = research.get('_id')
  return new Promise((resolve, reject) => {
    axios.put(`${researchApiUri}/${id}`, research, generateHeaderAuth(token))
      .then(resolve)
      .catch(err => {
        const defaultError = { message: 'Check your internet connection' }
        const { response = { } } = err
        const { data = defaultError } = response
        reject(data)
      })
  })
}

const deleteResearch = id => {
  const { token } = store.getState().Auth
  return new Promise((resolve, reject) => {
    axios.delete(`${researchApiUri}/${id}`, { ...generateHeaderAuth(token) })
      .then(resolve)
      .catch(err => {
        const defaultError = { message: 'Check your internet connection' }
        const { response = { } } = err
        const { data = defaultError } = response
        reject(data)
      })
  })
}

export default { fetchResearchs, createResearch, updateResearch, deleteResearch }
