import React, { Component } from 'react'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import {
  Button,
  IconButton,
  Tooltip,
  TextField
} from '@material-ui/core'
import {
  EditOutlined as EditIcon,
  DeleteOutlined as DeleteIcon,
  CloudUploadOutlined as UploadIcon,
  CreateOutlined as CreateIcon
} from '@material-ui/icons'
import env from '../../config/env'
import {
  MainTitle,
  Snackbar as MySnackbar,
  TableComponent,
  DialogConfirm,
  RichTextEditor
} from '../../components'
import {
  fetchArticles,
  createArticle,
  updateArticle,
  deleteArticle,
  resetArticle
} from '../../store/actions/admin/Article'

// const { uri: proxy } = env.server
const proxy = __dirname

const defaultInput = {
  create: {
    _id: '',
    title: '',
    body: '',
    image: ''
  },
  update: {
    _id: '',
    title: '',
    body: '',
    image: ''
  }
}

const defaultDialog = {
  delete: {
    attr: null,
    open: false
  },
  create: {
    attr: null,
    open: false
  },
  update: {
    attr: null,
    open: false
  }
}

class Article extends Component {
  constructor(props) {
    super(props)

    if (props.token === null) {
      props.history.push('/admin/login')
    }

    this.state = {
      input: defaultInput,
      dialog: defaultDialog
    }
  }

  componentDidMount() {
    this.resetError()
    this.props.fetchArticles()
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.Article.articles !== nextProps.Article.articles
      || this.props.Article.articles.length !== nextProps.Article.articles.length
    ) {
      this.setState({
        input: defaultInput,
        dialog: defaultDialog
      })
    }
  }

  prepareCreateArticle = () => {
    this.toggleDialog('create')
  }

  prepareUpdateArticle = id => {
    const { articles } = this.props.Article
    const articleData = articles.find(({ _id }) => _id === id)
    this.setState({
      input: {
        ...this.state.input,
        update: {
          ...articleData,
          image: ''
        }
      }
    }, () => {
      this.toggleDialog('update')
    })
  }

  prepareDeleteArticle = id => {
    this.toggleDialog('delete', id)
  }

  createArticle = () => {
    const { create: newArticle } = this.state.input
    const formData = new FormData()
    formData.set('title', newArticle.title)
    formData.set('body', newArticle.body)
    formData.set('image', newArticle.image)
    this.props.createArticle(formData)
  }

  updateArticle = () => {
    const { update: newArticle } = this.state.input
    const formData = new FormData()
    formData.set('_id', newArticle._id)
    formData.set('title', newArticle.title)
    formData.set('body', newArticle.body)
    formData.set('image', newArticle.image)
    this.props.updateArticle(formData)
  }

  deleteArticle = () => {
    const { attr: id } = this.state.dialog.delete
    this.props.deleteArticle(id)
  }

  toggleDialog = (name, attr = null) => {
    const { state } = this
    this.setState({
      dialog: {
        ...state.dialog,
        [name]: {
          ...state.dialog[name],
          attr: attr,
          open: !state.dialog[name].open
        }
      }
    })
  }

  onChangeInput = (e, name, fileInput = false) => {
    this.setState({
      input: {
        ...this.state.input,
        [name]: {
          ...this.state.input[name],
          [e.target.id]: fileInput
            ? e.target.files[0] ? e.target.files[0] : this.state.input[e.target.id]
            : e.target.value
        }
      }
    })
  }

  onChangeRichTextInput = (name, id) => {
    return value => {
      this.setState({
        input: {
          ...this.state.input,
          [name]: {
            ...this.state.input[name],
            [id]: value
          }
        }
      })
    }
  }

  resetError = () => {
    this.props.resetArticle()
  }

  render() {
    const {
      onChangeInput,
      onChangeRichTextInput,
      toggleDialog,
      prepareCreateArticle,
      prepareUpdateArticle,
      prepareDeleteArticle,
      createArticle,
      updateArticle,
      deleteArticle
    } = this
    const { open: deleteDialog } = this.state.dialog.delete
    const { open: createDialog } = this.state.dialog.create
    const { open: updateDialog } = this.state.dialog.update

    const { input } = this.state

    const { errors, isLoading } = this.props.Article
    let { articles } = this.props.Article
    articles = articles.map(article => ({
      ...article,
      image: (
        <div className='thumbnail'>
          <img
            src={`${proxy}/${article.image}`}
            alt={`${article.title}`}
            />
        </div>
      ),
      actions: (
        <div className='actions'>
          <Tooltip title='Update'>
            <IconButton onClick={() => prepareUpdateArticle(article._id)}>
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title='Delete'>
            <IconButton onClick={() => prepareDeleteArticle(article._id)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </div>
      )
    }))

    const schema = {
      actions: 'Aksi',
      image: 'Gambar',
      title: 'Judul'
    }

    return (
      <div>
        <MainTitle title={'Article'}/>
        <TableComponent
          title={'Artikel dan Berita'}
          data={articles}
          schema={schema}
          expanded={true}
          loading={isLoading}
          headerNode={(
            <Tooltip title='Create'>
              <Button
                variant='outlined'
                color='secondary'
                onClick={() => prepareCreateArticle()}>
                <CreateIcon fontSize='small'/>&nbsp;
                Tambah
              </Button>
            </Tooltip>
          )}
          />
        <DialogConfirm
          open={deleteDialog}
          title={'Hapus'}
          body={'Apakah anda yakin menghapus Artikel ini?'}
          onConfirm={() => deleteArticle()}
          onClose={() => toggleDialog('delete')}
          />
        <DialogConfirm
          fullScreen={true}
          open={createDialog}
          title={'Tambah'}
          body={'Isi beberapa field untuk Artikel anda'}
          onConfirm={() => createArticle()}
          onClose={() => toggleDialog('create')}
          inputNode={(
            <form className='dialog-form'>
              <TextField
                id='title'
                margin='dense'
                label='Judul'
                value={input.create.title}
                onChange={e => onChangeInput(e, 'create')}
                variant='outlined'
                fullWidth
              />
              <br /> <br />
              <div className='input-file'>
                <input
                  id='image'
                  type='file'
                  style={{ display: 'none' }}
                  onChange={e => onChangeInput(e, 'create', true)}
                  accept='image/x-png,image/jpeg'
                  />
                <label htmlFor='image'>
                  <Tooltip title='Upload image'>
                    <IconButton color='secondary' component='span'>
                      <UploadIcon />
                    </IconButton>
                  </Tooltip>
                </label>
                <TextField disabled placeholder={input.create.image.name} className='file-name-input' fullWidth/>
              </div>
              <br />
              <RichTextEditor
                valueFor='create'
                value={input.create.body}
                onChangeValue={onChangeRichTextInput('create', 'body')}
                />
            </form>
          )}
          />
        <DialogConfirm
          fullScreen={true}
          open={updateDialog}
          title={'Perbaruan'}
          body={'Perbarui beberapa field untuk Artikel anda'}
          onConfirm={() => updateArticle()}
          onClose={() => toggleDialog('update')}
          inputNode={(
            <form className='dialog-form'>
              <TextField
                id='title'
                margin='dense'
                label='Judul'
                value={input.update.title}
                onChange={e => onChangeInput(e, 'update')}
                variant='outlined'
                fullWidth
              />
              <br /> <br />
              <div className='input-file'>
                <input
                  id='image'
                  type='file'
                  style={{ display: 'none' }}
                  onChange={e => onChangeInput(e, 'update', true)}
                  accept='image/x-png,image/jpeg'
                  />
                <label htmlFor='image'>
                  <Tooltip title='Upload image'>
                    <IconButton color='secondary' component='span'>
                      <UploadIcon />
                    </IconButton>
                  </Tooltip>
                </label>
                <TextField disabled placeholder={input.update.image.name} className='file-name-input' fullWidth/>
              </div>
              <br /><br />
              <RichTextEditor
                valueFor='create'
                value={input.update.body}
                onChangeValue={onChangeRichTextInput('update', 'body')}
                />
            </form>
          )}
          />
        <MySnackbar
          variant={'error'}
          open={errors.status}
          message={errors.message}
          handleClose={this.resetError}
          />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  token: state.Auth.token,
  Article: state.AdminArticle
})

export default compose(
  connect(mapStateToProps, {
    fetchArticles,
    createArticle,
    updateArticle,
    deleteArticle,
    resetArticle
  }),
  withRouter
)(Article)
