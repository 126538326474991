import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import {
  Button,
  LinearProgress
} from '@material-ui/core'
import {
  ArticleCard,
  MainTitle,
  Snackbar as Mysnackbar
} from '../components'
import { fetchArticles, resetArticle } from '../store/actions/Article'

class Home extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.locations !== prevProps.location) {
      window.scrollTo(0,0)
    }
  }

  componentDidMount() {
    this.resetError()
    if (this.props.Article.articles.length <= 0) {
      this.onLoadMoreArticle()
    }
  }

  resetError = () => {
    this.props.resetArticle()
  }

  onLoadMoreArticle = () => {
    const { articles } = this.props.Article
    const offset = articles.length
    this.props.fetchArticles(offset)
  }

  render() {
    const title = 'Artikel dan Berita'
    const { articles, isLoading, isMax, errors } = this.props.Article

    return (
      <div className='home'>
        <Mysnackbar
          open={errors.status}
          message={errors.message}
          variant={'error'}
          handleClose={this.resetError}
          />
        <MainTitle title={title}/>
        <ArticleCard articles={articles}/>
        {
          isLoading
          ? <LinearProgress className='linear-progress' color='secondary'/>
          : null
        }
        {
          !isMax
          ? (
              <Fragment>
                <Button
                  disabled={isLoading}
                  size='large'
                  className='more-button'
                  variant='outlined'
                  onClick={() => this.onLoadMoreArticle()}
                  >
                  {'SELENGKAPNYA'}
                </Button>
              </Fragment>
            )
          : null
        }
      </div>
    )
  }
}

const mapStateToProps = ({ Article }) => ({
  Article: Article
})

export default connect(mapStateToProps, { fetchArticles, resetArticle })(Home)
