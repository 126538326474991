import { Admin as AdminTypes } from '../../types'

const {
  ADMIN_REQUEST_FETCH_RESEARCHS,
  ADMIN_REQUEST_CREATE_RESEARCH,
  ADMIN_REQUEST_UPDATE_RESEARCH,
  ADMIN_REQUEST_DELETE_RESEARCH,
  ADMIN_ACTION_RESEARCH_SUCCESS,
  ADMIN_ACTION_RESEARCH_ERROR,
  ADMIN_RESET_RESEARCH
} = AdminTypes.research

const initialState = {
  researchs: [],
  isLoading: false,
  errors: {
    status: false,
    message: null
  }
}

export default (state = initialState, action) => {
  switch (action.type) {

    case ADMIN_ACTION_RESEARCH_ERROR:
      return {
        ...state,
        isLoading: false,
        errors: {
          ...state.errors,
          status: true,
          message: action.payload.message
        }
      }

    case ADMIN_ACTION_RESEARCH_SUCCESS:
      return {
        ...state,
        researchs: action.payload.researchs,
        isLoading: false,
        errors: {
          ...state.errors,
          status: false,
          message: null
        }
      }

    case ADMIN_REQUEST_FETCH_RESEARCHS:
    case ADMIN_REQUEST_CREATE_RESEARCH:
    case ADMIN_REQUEST_UPDATE_RESEARCH:
    case ADMIN_REQUEST_DELETE_RESEARCH:
      return {
        ...state,
        isLoading: true,
        errors: {
          ...state.errors,
          status: false,
          message: null
        }
      }

    case ADMIN_RESET_RESEARCH:
      return {
        ...state,
        isLoading: false,
        errors: {
          ...state.errors,
          status: false,
          message: null
        }
      }

    default:
      return state

  }
}
