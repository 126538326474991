import React, { Component } from 'react'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import {
  MainTitle
} from '../../components'

class Home extends Component {
  constructor(props) {
    super(props)

    if (props.token === null) {
      props.history.push('/admin/login')
    }
  }

  render() {
    return (
      <div>
        <MainTitle title={'Home'}/>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  token: state.Auth.token
})

export default compose(
  connect(mapStateToProps),
  withRouter
)(Home)
