import React from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core'

const DialogConfirm = props => {
  const { open, title, body, inputNode = null, onConfirm, onClose, fullScreen = false } = props

  return (
    <Dialog
      open={open}
      fullScreen={fullScreen}
      >
      { title ? <DialogTitle>{title}</DialogTitle> : null }
      <DialogContent>
        { body ? <DialogContentText>{body}</DialogContentText> : null }
        {inputNode}
      </DialogContent>
      <DialogActions>
        <Button color='secondary' onClick={() => onClose()}>
          Cancel
        </Button>
        <Button color='secondary' onClick={() => onConfirm()}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  )
}

DialogConfirm.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
  body: PropTypes.string,
  inputNode: PropTypes.node,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
}

export default DialogConfirm
