import axios from 'axios'

import env from '../../../config/env'
import { store } from '../../index'
import generateHeaderAuth from '../../../utils/generate-authorization-header'

const { uri: proxy } = env.server
const hkiApiUri = `${proxy}/api/hki`

const fetchHkis = () => {
  return new Promise((resolve, reject) => {
    axios.get(`${hkiApiUri}`)
      .then(response => resolve(response.data))
      .catch(err => {
        const defaultError = { message: 'Check your internet connection' }
        const { response = { } } = err
        const { data = defaultError } = response
        reject(data)
      })
  })
}

const createHki = hki => {
  const { token } = store.getState().Auth
  return new Promise((resolve, reject) => {
    axios.post(`${hkiApiUri}`, hki, generateHeaderAuth(token))
      .then(resolve)
      .catch(err => {
        const defaultError = { message: 'Check your internet connection' }
        const { response = { } } = err
        const { data = defaultError } = response
        reject(data)
      })
  })
}

const updateHki = hki => {
  const { token } = store.getState().Auth
  const id = hki.get('_id')
  return new Promise((resolve, reject) => {
    axios.put(`${hkiApiUri}/${id}`, hki, generateHeaderAuth(token))
      .then(resolve)
      .catch(err => {
        const defaultError = { message: 'Check your internet connection' }
        const { response = { } } = err
        const { data = defaultError } = response
        reject(data)
      })
  })
}

const deleteHki = id => {
  const { token } = store.getState().Auth
  return new Promise((resolve, reject) => {
    axios.delete(`${hkiApiUri}/${id}`, { ...generateHeaderAuth(token) })
      .then(resolve)
      .catch(err => {
        const defaultError = { message: 'Check your internet connection' }
        const { response = { } } = err
        const { data = defaultError } = response
        reject(data)
      })
  })
}

export default { fetchHkis, createHki, updateHki, deleteHki }
