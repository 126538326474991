import axios from 'axios'

import env from '../../../config/env'
import { store } from '../../index'
import generateHeaderAuth from '../../../utils/generate-authorization-header'

const { uri: proxy } = env.server
const communityApiUri = `${proxy}/api/community`

const fetchCommunitys = () => {
  return new Promise((resolve, reject) => {
    axios.get(`${communityApiUri}`)
      .then(response => resolve(response.data))
      .catch(err => {
        const defaultError = { message: 'Check your internet connection' }
        const { response = { } } = err
        const { data = defaultError } = response
        reject(data)
      })
  })
}

const createCommunity = community => {
  const { token } = store.getState().Auth
  return new Promise((resolve, reject) => {
    axios.post(`${communityApiUri}`, community, generateHeaderAuth(token))
      .then(resolve)
      .catch(err => {
        const defaultError = { message: 'Check your internet connection' }
        const { response = { } } = err
        const { data = defaultError } = response
        reject(data)
      })
  })
}

const updateCommunity = community => {
  const { token } = store.getState().Auth
  const id = community.get('_id')
  return new Promise((resolve, reject) => {
    axios.put(`${communityApiUri}/${id}`, community, generateHeaderAuth(token))
      .then(resolve)
      .catch(err => {
        const defaultError = { message: 'Check your internet connection' }
        const { response = { } } = err
        const { data = defaultError } = response
        reject(data)
      })
  })
}

const deleteCommunity = id => {
  const { token } = store.getState().Auth
  return new Promise((resolve, reject) => {
    axios.delete(`${communityApiUri}/${id}`, { ...generateHeaderAuth(token) })
      .then(resolve)
      .catch(err => {
        const defaultError = { message: 'Check your internet connection' }
        const { response = { } } = err
        const { data = defaultError } = response
        reject(data)
      })
  })
}

export default { fetchCommunitys, createCommunity, updateCommunity, deleteCommunity }
