import { SelectedArticle as SelectedArticleTypes } from '../types'

const { REQUEST_FETCH_SELECTED_ARTICLE } = SelectedArticleTypes

export const fetchArticle = id => {
  return {
    type: REQUEST_FETCH_SELECTED_ARTICLE,
    payload: { id }
  }
}
