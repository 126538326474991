import { call, put } from 'redux-saga/effects'

import { Article as ArticleTypes } from '../types'
import { Article as ArticleApi } from '../api'

const {
  FETCH_ARTICLES_ERROR,
  FECTH_ARTICLES_SUCCESS
} = ArticleTypes

export default function* ({ payload }) {
  try {
    const response = yield call(ArticleApi.fetchArticles, payload.offset)
    yield put({
      type: FECTH_ARTICLES_SUCCESS,
      payload: {
        articles: response.data,
        count: response.count
      }
    })
  } catch (err) {
    yield put({
      type: FETCH_ARTICLES_ERROR,
      payload: {
        message: err.message
      }
    })
  }
}
