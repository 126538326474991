import { call, put } from 'redux-saga/effects'

import { Admin as AdminTypes } from '../../types'
import { Research as ResearchApi } from '../../api/admin'

const {
  ADMIN_REQUEST_FETCH_RESEARCHS,
  ADMIN_ACTION_RESEARCH_SUCCESS,
  ADMIN_ACTION_RESEARCH_ERROR
} = AdminTypes.research

function* fetchResearchs() {
  try {
    const response = yield call(ResearchApi.fetchResearchs)
    yield put({
      type: ADMIN_ACTION_RESEARCH_SUCCESS,
      payload: {
        researchs: response.data
      }
    })
  } catch (err) {
    yield put({
      type: ADMIN_ACTION_RESEARCH_ERROR,
      payload: {
        message: err.message
      }
    })
  }
}

function* createResearch({ payload }) {
  try {
    yield call(ResearchApi.createResearch, payload.research)
    yield put({
      type: ADMIN_REQUEST_FETCH_RESEARCHS
    })
  } catch (err) {
    yield put({
      type: ADMIN_ACTION_RESEARCH_ERROR,
      payload: {
        message: err.message
      }
    })
  }
}

function* updateResearch({ payload }) {
  try {
    yield call(ResearchApi.updateResearch, payload.research)
    yield put({
      type: ADMIN_REQUEST_FETCH_RESEARCHS
    })
  } catch (err) {
      yield put({
        type: ADMIN_ACTION_RESEARCH_ERROR,
        payload: {
          message: err.message
        }
      })
  }
}

function* deleteResearch({ payload }) {
  try {
    yield call(ResearchApi.deleteResearch, payload.id)
    yield put({
      type: ADMIN_REQUEST_FETCH_RESEARCHS
    })
  } catch (err) {
      yield put({
        type: ADMIN_ACTION_RESEARCH_ERROR,
        payload: {
          message: err.message
        }
      })
  }
}

export default { fetchResearchs, createResearch, updateResearch, deleteResearch }
