import React, { Component } from 'react'
import {
  Grid,
  Avatar,
  Typography,
  Fab
} from '@material-ui/core'
import {
  MainTitle
} from '../components'
import {
  EmailOutlined as EmailIcon
} from '@material-ui/icons'
import env from '../config/env'
import AvatarImage from '../assets/images/avatar.jpg'

class Profile extends Component {
  constructor(props) {
    super(props)
    this.state = {
      profile: env.profile
    }
  }

  render() {
    const title = 'Profil'
    const { profile } = this.state

    return (
      <div className='profile'>
        <MainTitle title={title}/>
        <br />
        <Grid container spacing={5} className='profile_wrapper'>
          <Grid xs={12} md={4} item className='thumbnail'>
            <Avatar src={AvatarImage} alt='avatar' className='avatar'/>
          </Grid>
          <Grid xs={12} md={8} item className='text'>
            <div className='header'>
              <Typography variant='h5' className='primary'>
                {profile.nama}
              </Typography>
              <Typography variant='body1' className='secondary'>
                {profile.jurusan}
              </Typography>
            </div>
            <div className='body'>
              <Grid container>
                <Grid item xs={3}>
                  <Typography variant='body2' className='primary'>
                    {'NIDN'}<br />
                    {'ALAMAT'}<br />
                    {'NO. TELP'}<br />
                    {'E-MAIL'}<br />
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography variant='body2' className='secondary'>
                    {profile.nidn}<br />
                    {profile.alamat}<br />
                    {profile.telp}<br />
                    {profile.email}<br />
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <br />
                  <Fab size='small' color='secondary' href={`mailto:${profile.email}`}>
                    <EmailIcon />
                  </Fab>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
        <br />
      </div>
    )
  }
}

export default Profile
