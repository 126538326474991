import { Admin as AdminTypes } from '../../types'

const {
  ADMIN_REQUEST_FETCH_COMMUNITYS,
  ADMIN_REQUEST_CREATE_COMMUNITY,
  ADMIN_REQUEST_UPDATE_COMMUNITY,
  ADMIN_REQUEST_DELETE_COMMUNITY,
  ADMIN_ACTION_COMMUNITY_SUCCESS,
  ADMIN_ACTION_COMMUNITY_ERROR,
  ADMIN_RESET_COMMUNITY
} = AdminTypes.community

const initialState = {
  communitys: [],
  isLoading: false,
  errors: {
    status: false,
    message: null
  }
}

export default (state = initialState, action) => {
  switch (action.type) {

    case ADMIN_ACTION_COMMUNITY_ERROR:
      return {
        ...state,
        isLoading: false,
        errors: {
          ...state.errors,
          status: true,
          message: action.payload.message
        }
      }

    case ADMIN_ACTION_COMMUNITY_SUCCESS:
      return {
        ...state,
        communitys: action.payload.communitys,
        isLoading: false,
        errors: {
          ...state.errors,
          status: false,
          message: null
        }
      }

    case ADMIN_REQUEST_FETCH_COMMUNITYS:
    case ADMIN_REQUEST_CREATE_COMMUNITY:
    case ADMIN_REQUEST_UPDATE_COMMUNITY:
    case ADMIN_REQUEST_DELETE_COMMUNITY:
      return {
        ...state,
        isLoading: true,
        errors: {
          ...state.errors,
          status: false,
          message: null
        }
      }

    case ADMIN_RESET_COMMUNITY:
      return {
        ...state,
        isLoading: false,
        errors: {
          ...state.errors,
          status: false,
          message: null
        }
      }

    default:
      return state

  }
}
