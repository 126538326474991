const server = {
  uri: ''
}

const profile = {
  nama: 'Rico Sandyca Novenza',
  nidn: '123456789',
  alamat: 'Jalan Jaksa Agung Suprapto',
  telp: '083881881881',
  email: 'ricosandyca.if@gmail.com',
  jurusan: 'Teknik Informatika',
  akademik: [
    {
      tahun: '2001 - 2002',
      tingkat: 'S1',
      jurusan: 'Teknik Informatika',
      universitas: 'Universitas UPN "VETERAN" Jawa Timur'
    },
    {
      tahun: '2002 - 2003',
      tingkat: 'S2',
      jurusan: 'Teknik Informatika',
      universitas: 'Universitas UPN "VETERAN" Jawa Timur'
    },
    {
      tahun: '2004 - 2005',
      tingkat: 'S3',
      jurusan: 'Teknik Informatika',
      universitas: 'Universitas UPN "VETERAN" Jawa Timur'
    }
  ],
  akademikSecond: [
    {
      tahun: '1991-1992',
      tingkat: 'TK',
      jurusan: '-',
      nama_sekolah: 'TK Kanak-kanak Surabaya'
    },
    {
      tahun: '1992-1995',
      tingkat: 'SD',
      jurusan: '-',
      nama_sekolah: 'SDN 1 Surabaya'
    },
    {
      tahun: '1996-1998',
      tingkat: 'SMP',
      jurusan: '-',
      nama_sekolah: 'SMPN 1 Surabaya'
    },
    {
      tahun: '1999-2000',
      tingkat: 'SMA',
      jurusan: 'IPA',
      nama_sekolah: 'SMAN 1 Surabaya'
    }
  ]
}

export default { profile, server }
