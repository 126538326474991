import React, { Fragment } from 'react'
import {
  Typography,
  Hidden
} from '@material-ui/core'

export default props => (
  <Fragment>
    <Hidden smDown>
      <Typography variant='h5' className='main_title'>{props.title}</Typography>
    </Hidden>
  </Fragment>
)
