import { call, put } from 'redux-saga/effects'

import { Admin as AdminTypes } from '../../types'
import { Paper as PaperApi } from '../../api/admin'

const {
  ADMIN_REQUEST_FETCH_PAPERS,
  ADMIN_ACTION_PAPER_SUCCESS,
  ADMIN_ACTION_PAPER_ERROR
} = AdminTypes.paper

function* fetchPapers() {
  try {
    const response = yield call(PaperApi.fetchPapers)
    yield put({
      type: ADMIN_ACTION_PAPER_SUCCESS,
      payload: {
        papers: response.data
      }
    })
  } catch (err) {
    yield put({
      type: ADMIN_ACTION_PAPER_ERROR,
      payload: {
        message: err.message
      }
    })
  }
}

function* createPaper({ payload }) {
  try {
    yield call(PaperApi.createPaper, payload.paper)
    yield put({
      type: ADMIN_REQUEST_FETCH_PAPERS
    })
  } catch (err) {
    yield put({
      type: ADMIN_ACTION_PAPER_ERROR,
      payload: {
        message: err.message
      }
    })
  }
}

function* updatePaper({ payload }) {
  try {
    yield call(PaperApi.updatePaper, payload.paper)
    yield put({
      type: ADMIN_REQUEST_FETCH_PAPERS
    })
  } catch (err) {
      yield put({
        type: ADMIN_ACTION_PAPER_ERROR,
        payload: {
          message: err.message
        }
      })
  }
}

function* deletePaper({ payload }) {
  try {
    yield call(PaperApi.deletePaper, payload.id)
    yield put({
      type: ADMIN_REQUEST_FETCH_PAPERS
    })
  } catch (err) {
      yield put({
        type: ADMIN_ACTION_PAPER_ERROR,
        payload: {
          message: err.message
        }
      })
  }
}

export default { fetchPapers, createPaper, updatePaper, deletePaper }
