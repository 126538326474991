import { Admin as AdminTypes } from '../../types'

const {
  ADMIN_REQUEST_FETCH_BOOKS,
  ADMIN_REQUEST_CREATE_BOOK,
  ADMIN_REQUEST_UPDATE_BOOK,
  ADMIN_REQUEST_DELETE_BOOK,
  ADMIN_ACTION_BOOK_SUCCESS,
  ADMIN_ACTION_BOOK_ERROR,
  ADMIN_RESET_BOOK
} = AdminTypes.book

const initialState = {
  books: [],
  isLoading: false,
  errors: {
    status: false,
    message: null
  }
}

export default (state = initialState, action) => {
  switch (action.type) {

    case ADMIN_ACTION_BOOK_ERROR:
      return {
        ...state,
        isLoading: false,
        errors: {
          ...state.errors,
          status: true,
          message: action.payload.message
        }
      }

    case ADMIN_ACTION_BOOK_SUCCESS:
      return {
        ...state,
        books: action.payload.books,
        isLoading: false,
        errors: {
          ...state.errors,
          status: false,
          message: null
        }
      }

    case ADMIN_REQUEST_FETCH_BOOKS:
    case ADMIN_REQUEST_CREATE_BOOK:
    case ADMIN_REQUEST_UPDATE_BOOK:
    case ADMIN_REQUEST_DELETE_BOOK:
      return {
        ...state,
        isLoading: true,
        errors: {
          ...state.errors,
          status: false,
          message: null
        }
      }

    case ADMIN_RESET_BOOK:
      return {
        ...state,
        isLoading: false,
        errors: {
          ...state.errors,
          status: false,
          message: null
        }
      }

    default:
      return state

  }
}
