import { Paper as PaperTypes } from '../types'

const { REQUEST_FETCH_PAPERS, RESET_PAPER } = PaperTypes

export const fetchPapers = () => {
  return {
    type: REQUEST_FETCH_PAPERS
  }
}

export const resetPaper = () => {
  return {
    type: RESET_PAPER
  }
}
