import axios from 'axios'

import env from '../../config/env'

const { uri: proxy } = env.server

const fetchSupports = () => {
  return new Promise((resolve, reject) => {
    axios.get(`${proxy}/api/support`)
      .then(response => resolve(response.data))
      .catch(err => {
        const defaultError = { message: 'Check your internet connection' }
        const { response = { } } = err
        const { data = defaultError } = response
        reject(data)
      })
  })
}

export default { fetchSupports }
