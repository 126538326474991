import { call, put } from 'redux-saga/effects'

import { Admin as AdminTypes } from '../../types'
import { HKI as HKIApi } from '../../api/admin'

const {
  ADMIN_REQUEST_FETCH_HKIS,
  ADMIN_ACTION_HKI_SUCCESS,
  ADMIN_ACTION_HKI_ERROR
} = AdminTypes.hki

function* fetchHkis() {
  try {
    const response = yield call(HKIApi.fetchHkis)
    yield put({
      type: ADMIN_ACTION_HKI_SUCCESS,
      payload: {
        hkis: response.data
      }
    })
  } catch (err) {
    yield put({
      type: ADMIN_ACTION_HKI_ERROR,
      payload: {
        message: err.message
      }
    })
  }
}

function* createHki({ payload }) {
  try {
    yield call(HKIApi.createHki, payload.hki)
    yield put({
      type: ADMIN_REQUEST_FETCH_HKIS
    })
  } catch (err) {
    yield put({
      type: ADMIN_ACTION_HKI_ERROR,
      payload: {
        message: err.message
      }
    })
  }
}

function* updateHki({ payload }) {
  try {
    yield call(HKIApi.updateHki, payload.hki)
    yield put({
      type: ADMIN_REQUEST_FETCH_HKIS
    })
  } catch (err) {
    yield put({
      type: ADMIN_ACTION_HKI_ERROR,
      payload: {
        message: err.message
      }
    })
  }
}

function* deleteHki({ payload }) {
  try {
    yield call(HKIApi.deleteHki, payload.id)
    yield put({
      type: ADMIN_REQUEST_FETCH_HKIS
    })
  } catch (err) {
    yield put({
      type: ADMIN_ACTION_HKI_ERROR,
      payload: {
        message: err.message
      }
    })
  }
}

export default { fetchHkis, createHki, updateHki, deleteHki }
