import React, { Component } from 'react'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import {
  Button,
  IconButton,
  Tooltip,
  TextField
} from '@material-ui/core'
import {
  EditOutlined as EditIcon,
  DeleteOutlined as DeleteIcon,
  CloudUploadOutlined as UploadIcon,
  CreateOutlined as CreateIcon
} from '@material-ui/icons'

import env from '../../config/env'
import {
  MainTitle,
  Snackbar as MySnackbar,
  TableComponent,
  DialogConfirm
} from '../../components'
import {
  fetchSupports,
  createSupport,
  updateSupport,
  deleteSupport,
  resetSupport
} from '../../store/actions/admin/Support'

const { uri: proxy } = env.server

const defaultInput = {
  create: {
    _id: '',
    type: '',
    place: '',
    year: '',
    file: ''
  },
  update: {
    _id: '',
    type: '',
    place: '',
    year: '',
    file: ''
  }
}

const defaultDialog = {
  delete: {
    attr: null,
    open: false
  },
  create: {
    attr: null,
    open: false
  },
  update: {
    attr: null,
    open: false
  }
}

class Support extends Component {
  constructor(props) {
    super(props)

    if (props.token === null) {
      props.history.push('/admin/login')
    }

    this.state = {
      input: defaultInput,
      dialog: defaultDialog
    }
  }

  componentDidMount() {
    this.resetError()
    this.props.fetchSupports()
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.Support.supports !== nextProps.Support.supports
      || this.props.Support.supports.length !== nextProps.Support.supports.length
    ) {
      this.setState({
        input: defaultInput,
        dialog: defaultDialog
      })
    }
  }

  preparecreateSupport = () => {
    this.toggleDialog('create')
  }

  prepareupdateSupport = id => {
    const { supports } = this.props.Support
    const supportData = supports.find(({ _id }) => _id === id)
    this.setState({
      input: {
        ...this.state.input,
        update: {
          ...supportData,
          image: ''
        }
      }
    }, () => {
      this.toggleDialog('update')
    })
  }

  preparedeleteSupport = id => {
    this.toggleDialog('delete', id)
  }

  createSupport = () => {
    const { create: newSupport } = this.state.input
    const formData = new FormData()
    formData.set('type', newSupport.type)
    formData.set('place', newSupport.place)
    formData.set('year', newSupport.year)
    formData.set('file', newSupport.file)
    this.props.createSupport(formData)
  }

  updateSupport = () => {
    const { update: newSupport } = this.state.input
    const formData = new FormData()
    formData.set('_id', newSupport._id)
    formData.set('type', newSupport.type)
    formData.set('place', newSupport.place)
    formData.set('year', newSupport.year)
    formData.set('file', newSupport.file)
    this.props.updateSupport(formData)
  }

  deleteSupport = () => {
    const { attr: id } = this.state.dialog.delete
    this.props.deleteSupport(id)
  }

  toggleDialog = (name, attr = null) => {
    const { state } = this
    this.setState({
      dialog: {
        ...state.dialog,
        [name]: {
          ...state.dialog[name],
          attr: attr,
          open: !state.dialog[name].open
        }
      }
    })
  }

  onChangeInput = (e, name, fileInput = false) => {
    this.setState({
      input: {
        ...this.state.input,
        [name]: {
          ...this.state.input[name],
          [e.target.id]: fileInput
            ? e.target.files[0] ? e.target.files[0] : this.state.input[e.target.id]
            : e.target.value
        }
      }
    })
  }

  resetError = () => {
    this.props.resetSupport()
  }

  render() {
    const {
      onChangeInput,
      toggleDialog,
      preparecreateSupport,
      prepareupdateSupport,
      preparedeleteSupport,
      createSupport,
      updateSupport,
      deleteSupport
    } = this
    const { open: deleteDialog } = this.state.dialog.delete
    const { open: createDialog } = this.state.dialog.create
    const { open: updateDialog } = this.state.dialog.update

    const { input } = this.state
    const { errors, isLoading } = this.props.Support
    let { supports } = this.props.Support
    supports = supports.map(support => ({
      ...support,
      file: (<Button
        color='secondary'
        variant='outlined'
        target='_blank'
        href={`${proxy}/${Support.file}`}
        >{'UNDUH'}</Button>),
      actions: (
        <div className='actions'>
          <Tooltip title='Update'>
            <IconButton onClick={() => prepareupdateSupport(support._id)}>
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title='Delete'>
            <IconButton onClick={() => preparedeleteSupport(support._id)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </div>
      )
    }))

    const schema = {
      actions: 'Aksi',
      type: 'Tipe',
      place: 'Tempat',
      year: 'Tahun',
      file: 'Berkas'
    }

    const title = 'Penunjang'

    return (
      <div>
        <MainTitle title={title} />
        <TableComponent
          title={'Penunjang'}
          data={supports}
          schema={schema}
          expanded={true}
          loading={isLoading}
          headerNode={(
            <Tooltip title='Create'>
              <Button
                variant='outlined'
                color='secondary'
                onClick={() => preparecreateSupport()}>
                <CreateIcon fontSize='small'/>&nbsp;
                Tambah
              </Button>
            </Tooltip>
          )}
          />
        <DialogConfirm
          open={deleteDialog}
          title={'Hapus'}
          body={'Apakah anda yakin menghapus Penunjang ini?'}
          onConfirm={() => deleteSupport()}
          onClose={() => toggleDialog('delete')}
          />
        <DialogConfirm
          open={createDialog}
          title={'Tambah'}
          body={'Masukkan beberapa informasi Penunjang anda dibawah'}
          onConfirm={() => createSupport()}
          onClose={() => toggleDialog('create')}
          inputNode={(
            <form className='dialog-form'>
              <TextField
                id='type'
                margin='dense'
                label='Tipe'
                value={input.create.type}
                onChange={e => onChangeInput(e, 'create')}
                variant='outlined'
                fullWidth
              />
              <TextField
                id='place'
                margin='dense'
                label='Tempat'
                value={input.create.place}
                onChange={e => onChangeInput(e, 'create')}
                variant='outlined'
                fullWidth
              />
              <TextField
                id='year'
                margin='normal'
                label='Tahun'
                value={input.create.year}
                onChange={e => onChangeInput(e, 'create')}
                variant='outlined'
                fullWidth
              />
            <br /><br />
            <div className='input-file'>
              <input
                id='file'
                type='file'
                style={{ display: 'none' }}
                onChange={e => onChangeInput(e, 'create', true)}
                accept='application/pdf'
                />
              <label htmlFor='file'>
                <Tooltip title='Upload file'>
                  <IconButton color='secondary' component='span'>
                    <UploadIcon />
                  </IconButton>
                </Tooltip>
              </label>
              <TextField
                disabled
                placeholder={input.create.file.name}
                fullWidth
                className='file-name-input'
                />
            </div>
            </form>
          )}
          />
        <DialogConfirm
          open={updateDialog}
          title={'Perbaruan'}
          body={'Masukkan perbaruan informasi Penunjang anda dibawah'}
          onConfirm={() => updateSupport()}
          onClose={() => toggleDialog('update')}
          inputNode={(
            <form className='dialog-form'>
              <TextField
                id='type'
                margin='dense'
                label='Tipe'
                value={input.update.type}
                onChange={e => onChangeInput(e, 'update')}
                variant='outlined'
                fullWidth
              />
              <TextField
                id='place'
                margin='dense'
                label='Tempat'
                value={input.update.place}
                onChange={e => onChangeInput(e, 'update')}
                variant='outlined'
                fullWidth
              />
              <TextField
                id='year'
                margin='normal'
                label='Tahun'
                value={input.update.year}
                onChange={e => onChangeInput(e, 'update')}
                variant='outlined'
                fullWidth
              />
              <br /><br />
              <div className='input-file'>
                <input
                  id='file'
                  type='file'
                  style={{ display: 'none' }}
                  onChange={e => onChangeInput(e, 'update', true)}
                  />
                <label htmlFor='file'>
                  <Tooltip title='Upload file'>
                    <IconButton color='secondary' component='span'>
                      <UploadIcon />
                    </IconButton>
                  </Tooltip>
                </label>
                <TextField disabled placeholder={input.update.file.name} fullWidth  className='file-name-input'/>
              </div>
            </form>
          )}
          />
        <MySnackbar
          variant={'error'}
          open={errors.status}
          message={errors.message}
          handleClose={this.resetError}
          />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  token: state.Auth.token,
  Support: state.AdminSupport
})

export default compose(
  connect(mapStateToProps, {
    fetchSupports,
    createSupport,
    updateSupport,
    deleteSupport,
    resetSupport
  }),
  withRouter
)(Support)
