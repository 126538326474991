import { Article as ArticleTypes } from '../types'

const { REQUEST_FETCH_ARTICLES, RESET_ARTICLE } = ArticleTypes

export const fetchArticles = offset => {
  return {
    type: REQUEST_FETCH_ARTICLES,
    payload: { offset }
  }
}

export const resetArticle = () => {
  return {
    type: RESET_ARTICLE
  }
}
