import { Admin as AdminTypes } from '../../types'

const {
  ADMIN_REQUEST_FETCH_HKIS,
  ADMIN_REQUEST_CREATE_HKI,
  ADMIN_REQUEST_UPDATE_HKI,
  ADMIN_REQUEST_DELETE_HKI,
  ADMIN_RESET_HKI
} = AdminTypes.hki

export const fetchHkis = () => {
  return {
    type: ADMIN_REQUEST_FETCH_HKIS
  }
}

export const createHki = hki => {
  return {
    type: ADMIN_REQUEST_CREATE_HKI,
    payload: { hki }
  }
}

export const updateHki = hki => {
  return {
    type: ADMIN_REQUEST_UPDATE_HKI,
    payload: { hki }
  }
}

export const deleteHki = id => {
  return {
    type: ADMIN_REQUEST_DELETE_HKI,
    payload: { id }
  }
}

export const resetHki = () => {
  return {
    type: ADMIN_RESET_HKI
  }
}
