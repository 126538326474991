import { Admin as AdminTypes } from '../../types'

const {
  ADMIN_REQUEST_FETCH_HKIS,
  ADMIN_REQUEST_CREATE_HKI,
  ADMIN_REQUEST_UPDATE_HKI,
  ADMIN_REQUEST_DELETE_HKI,
  ADMIN_ACTION_HKI_SUCCESS,
  ADMIN_ACTION_HKI_ERROR,
  ADMIN_RESET_HKI
} = AdminTypes.hki

const initialState = {
  hkis: [],
  isLoading: false,
  errors: {
    status: false,
    message: null
  }
}

export default (state = initialState, action) => {
  switch (action.type) {

    case ADMIN_ACTION_HKI_ERROR:
      return {
        ...state,
        isLoading: false,
        errors: {
          ...state.errors,
          status: true,
          message: action.payload.message
        }
      }

    case ADMIN_ACTION_HKI_SUCCESS:
      return {
        ...state,
        hkis: action.payload.hkis,
        isLoading: false,
        errors: {
          ...state.errors,
          status: false,
          message: null
        }
      }

    case ADMIN_REQUEST_FETCH_HKIS:
    case ADMIN_REQUEST_CREATE_HKI:
    case ADMIN_REQUEST_UPDATE_HKI:
    case ADMIN_REQUEST_DELETE_HKI:
      return {
        ...state,
        isLoading: true,
        errors: {
          ...state.errors,
          status: false,
          message: null
        }
      }

    case ADMIN_RESET_HKI:
      return {
        ...state,
        isLoading: false,
        errors: {
          ...state.errors,
          status: false,
          message: null
        }
      }

    default:
      return state

  }
}
