import { takeEvery } from 'redux-saga/effects'

import AuthWorker from './Auth'
import ArticleWorker from './Article'
import PaperWorker from './Paper'
import SelectedArticleWorker from './SelectedArticle'
import CommunityWorker from './Community'
import SupportWorker from './Support'
import {
  Article as AdminArticleWorker,
  Paper as AdminPaperWorker,
  Research as AdminResearchWorker,
  Book as AdminBookWorker,
  HKI as AdminHKIWorker,
  Community as AdminCommunityWorker,
  Support as AdminSupportWorker
} from './admin'
import {
  Admin as AdminTypes,
  Auth as AuthTypes,
  Article as ArticleTypes,
  Paper as PaperTypes,
  SelectedArticle as SelectedArticleTypes,
  Community as CommunityTypes,
  Support as SupportTypes
} from '../types'

const { REQUEST_LOGIN } = AuthTypes
const { REQUEST_FETCH_ARTICLES } = ArticleTypes
const { REQUEST_FETCH_PAPERS } = PaperTypes
const { REQUEST_FETCH_SELECTED_ARTICLE } = SelectedArticleTypes
const { REQUEST_FETCH_COMMUNITYS } = CommunityTypes
const { REQUEST_FETCH_SUPPORTS } = SupportTypes
const {
  ADMIN_REQUEST_FETCH_ARTICLES,
  ADMIN_REQUEST_CREATE_ARTICLE,
  ADMIN_REQUEST_UPDATE_ARTICLE,
  ADMIN_REQUEST_DELETE_ARTICLE
} = AdminTypes.article
const {
  ADMIN_REQUEST_FETCH_PAPERS,
  ADMIN_REQUEST_CREATE_PAPER,
  ADMIN_REQUEST_UPDATE_PAPER,
  ADMIN_REQUEST_DELETE_PAPER
} = AdminTypes.paper
const {
  ADMIN_REQUEST_FETCH_RESEARCHS,
  ADMIN_REQUEST_CREATE_RESEARCH,
  ADMIN_REQUEST_UPDATE_RESEARCH,
  ADMIN_REQUEST_DELETE_RESEARCH
} = AdminTypes.research
const {
  ADMIN_REQUEST_FETCH_BOOKS,
  ADMIN_REQUEST_CREATE_BOOK,
  ADMIN_REQUEST_UPDATE_BOOK,
  ADMIN_REQUEST_DELETE_BOOK
} = AdminTypes.book
const {
  ADMIN_REQUEST_FETCH_HKIS,
  ADMIN_REQUEST_CREATE_HKI,
  ADMIN_REQUEST_UPDATE_HKI,
  ADMIN_REQUEST_DELETE_HKI
} = AdminTypes.hki
const {
  ADMIN_REQUEST_FETCH_COMMUNITYS,
  ADMIN_REQUEST_CREATE_COMMUNITY,
  ADMIN_REQUEST_UPDATE_COMMUNITY,
  ADMIN_REQUEST_DELETE_COMMUNITY
} = AdminTypes.community
const {
  ADMIN_REQUEST_FETCH_SUPPORTS,
  ADMIN_REQUEST_CREATE_SUPPORT,
  ADMIN_REQUEST_UPDATE_SUPPORT,
  ADMIN_REQUEST_DELETE_SUPPORT
} = AdminTypes.support

export default function* watchers() {
  // Auth sagas
  yield takeEvery(REQUEST_LOGIN, AuthWorker)

  // Article sagas
  yield takeEvery(REQUEST_FETCH_ARTICLES, ArticleWorker)

  // Paper sagas
  yield takeEvery(REQUEST_FETCH_PAPERS, PaperWorker)

  // SelectedArticle saga
  yield takeEvery(REQUEST_FETCH_SELECTED_ARTICLE, SelectedArticleWorker)

  // Community watcher
  yield takeEvery(REQUEST_FETCH_COMMUNITYS, CommunityWorker)

  // Support watcher
  yield takeEvery(REQUEST_FETCH_SUPPORTS, SupportWorker)

  // Admin article watcher
  yield takeEvery(ADMIN_REQUEST_FETCH_ARTICLES, AdminArticleWorker.fetchArticles)
  yield takeEvery(ADMIN_REQUEST_CREATE_ARTICLE, AdminArticleWorker.createArticle)
  yield takeEvery(ADMIN_REQUEST_UPDATE_ARTICLE, AdminArticleWorker.updateArticle)
  yield takeEvery(ADMIN_REQUEST_DELETE_ARTICLE, AdminArticleWorker.deleteArticle)

  // Admin paper watcher
  yield takeEvery(ADMIN_REQUEST_FETCH_PAPERS, AdminPaperWorker.fetchPapers)
  yield takeEvery(ADMIN_REQUEST_CREATE_PAPER, AdminPaperWorker.createPaper)
  yield takeEvery(ADMIN_REQUEST_UPDATE_PAPER, AdminPaperWorker.updatePaper)
  yield takeEvery(ADMIN_REQUEST_DELETE_PAPER, AdminPaperWorker.deletePaper)

  // Admin research watcher
  yield takeEvery(ADMIN_REQUEST_FETCH_RESEARCHS, AdminResearchWorker.fetchResearchs)
  yield takeEvery(ADMIN_REQUEST_CREATE_RESEARCH, AdminResearchWorker.createResearch)
  yield takeEvery(ADMIN_REQUEST_UPDATE_RESEARCH, AdminResearchWorker.updateResearch)
  yield takeEvery(ADMIN_REQUEST_DELETE_RESEARCH, AdminResearchWorker.deleteResearch)

  // Admin book watcher
  yield takeEvery(ADMIN_REQUEST_FETCH_BOOKS, AdminBookWorker.fetchBooks)
  yield takeEvery(ADMIN_REQUEST_CREATE_BOOK, AdminBookWorker.createBook)
  yield takeEvery(ADMIN_REQUEST_UPDATE_BOOK, AdminBookWorker.updateBook)
  yield takeEvery(ADMIN_REQUEST_DELETE_BOOK, AdminBookWorker.deleteBook)

  // Admin hki watcher
  yield takeEvery(ADMIN_REQUEST_FETCH_HKIS, AdminHKIWorker.fetchHkis)
  yield takeEvery(ADMIN_REQUEST_CREATE_HKI, AdminHKIWorker.createHki)
  yield takeEvery(ADMIN_REQUEST_UPDATE_HKI, AdminHKIWorker.updateHki)
  yield takeEvery(ADMIN_REQUEST_DELETE_HKI, AdminHKIWorker.deleteHki)

  // Admin community watcher
  yield takeEvery(ADMIN_REQUEST_FETCH_COMMUNITYS, AdminCommunityWorker.fetchCommunitys)
  yield takeEvery(ADMIN_REQUEST_CREATE_COMMUNITY, AdminCommunityWorker.createCommunity)
  yield takeEvery(ADMIN_REQUEST_UPDATE_COMMUNITY, AdminCommunityWorker.updateCommunity)
  yield takeEvery(ADMIN_REQUEST_DELETE_COMMUNITY, AdminCommunityWorker.deleteCommunity)

  // Admin support worker
  yield takeEvery(ADMIN_REQUEST_FETCH_SUPPORTS, AdminSupportWorker.fetchSupports)
  yield takeEvery(ADMIN_REQUEST_CREATE_SUPPORT, AdminSupportWorker.createSupport)
  yield takeEvery(ADMIN_REQUEST_UPDATE_SUPPORT, AdminSupportWorker.updateSupport)
  yield takeEvery(ADMIN_REQUEST_DELETE_SUPPORT, AdminSupportWorker.deleteSupport)
}
