import { Support as SupportTypes } from '../types'

const { REQUEST_FETCH_SUPPORTS, RESET_SUPPORT } = SupportTypes

export const fetchSupports = () => {
  return {
    type: REQUEST_FETCH_SUPPORTS
  }
}

export const resetSupport = () => {
  return {
    type: RESET_SUPPORT
  }
}
