import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import CKEditor from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

const RichTextEditor = props => {
  const { value, onChangeValue} = props

  return (
    <Fragment>
      <CKEditor
        editor={ClassicEditor}
        data={value}
        onChange={(e, value) => onChangeValue(value.getData())}
        config={{
          toolbar: ['heading', '|', 'bold', 'italic', 'blockquote']
        }}
        />
    </Fragment>
  )
}

RichTextEditor.propTypes = {
  value: PropTypes.string.isRequired,
  onChangeValue: PropTypes.func.isRequired
}

export default RichTextEditor
