import { Community as CommunityTypes } from '../types'

const { REQUEST_FETCH_COMMUNITYS, RESET_COMMUNITY } = CommunityTypes

export const fetchCommunitys = () => {
  return {
    type: REQUEST_FETCH_COMMUNITYS
  }
}

export const resetCommunity = () => {
  return {
    type: RESET_COMMUNITY
  }
}
