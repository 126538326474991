export default {
  article: {
    ADMIN_REQUEST_FETCH_ARTICLES: 'ADMIN_REQUEST_FETCH_ARTICLES',
    ADMIN_REQUEST_CREATE_ARTICLE: 'ADMIN_REQUEST_CREATE_ARTICLE',
    ADMIN_REQUEST_UPDATE_ARTICLE: 'ADMIN_REQUEST_UPDATE_ARTICLE',
    ADMIN_REQUEST_DELETE_ARTICLE: 'ADMIN_REQUEST_DELETE_ARTICLE',
    ADMIN_ACTION_ARTICLE_SUCCESS: 'ADMIN_ACTION_ARTICLE_SUCCESS',
    ADMIN_ACTION_ARTICLE_ERROR: 'ADMIN_ACTION_ARTICLE_ERROR',
    ADMIN_RESET_ARTICLE: 'ADMIN_RESET_ARTICLE'
  },
  paper: {
    ADMIN_REQUEST_FETCH_PAPERS: 'ADMIN_REQUEST_FETCH_PAPERS',
    ADMIN_REQUEST_CREATE_PAPER: 'ADMIN_REQUEST_CREATE_PAPER',
    ADMIN_REQUEST_UPDATE_PAPER: 'ADMIN_REQUEST_UPDATE_PAPER',
    ADMIN_REQUEST_DELETE_PAPER: 'ADMIN_REQUEST_DELETE_PAPER',
    ADMIN_ACTION_PAPER_SUCCESS: 'ADMIN_ACTION_PAPER_SUCCESS',
    ADMIN_ACTION_PAPER_ERROR: 'ADMIN_ACTION_PAPER_ERROR',
    ADMIN_RESET_PAPER: 'ADMIN_RESET_PAPER'
  },
  research: {
    ADMIN_REQUEST_FETCH_RESEARCHS: 'ADMIN_REQUEST_FETCH_RESEARCHS',
    ADMIN_REQUEST_CREATE_RESEARCH: 'ADMIN_REQUEST_CREATE_RESEARCH',
    ADMIN_REQUEST_UPDATE_RESEARCH: 'ADMIN_REQUEST_UPDATE_RESEARCH',
    ADMIN_REQUEST_DELETE_RESEARCH: 'ADMIN_REQUEST_DELETE_RESEARCH',
    ADMIN_ACTION_RESEARCH_SUCCESS: 'ADMIN_ACTION_RESEARCH_SUCCESS',
    ADMIN_ACTION_RESEARCH_ERROR: 'ADMIN_ACTION_RESEARCH_ERROR',
    ADMIN_RESET_RESEARCH: 'ADMIN_RESET_RESEARCH'
  },
  book: {
    ADMIN_REQUEST_FETCH_BOOKS: 'ADMIN_REQUEST_FETCH_BOOKS',
    ADMIN_REQUEST_CREATE_BOOK: 'ADMIN_REQUEST_CREATE_BOOK',
    ADMIN_REQUEST_UPDATE_BOOK: 'ADMIN_REQUEST_UPDATE_BOOK',
    ADMIN_REQUEST_DELETE_BOOK: 'ADMIN_REQUEST_DELETE_BOOK',
    ADMIN_ACTION_BOOK_SUCCESS: 'ADMIN_ACTION_BOOK_SUCCESS',
    ADMIN_ACTION_BOOK_ERROR: 'ADMIN_ACTION_BOOK_ERROR',
    ADMIN_RESET_BOOK: 'ADMIN_RESET_BOOK'
  },
  hki: {
    ADMIN_REQUEST_FETCH_HKIS: 'ADMIN_REQUEST_FETCH_HKIS',
    ADMIN_REQUEST_CREATE_HKI: 'ADMIN_REQUEST_CREATE_HKI',
    ADMIN_REQUEST_UPDATE_HKI: 'ADMIN_REQUEST_UPDATE_HKI',
    ADMIN_REQUEST_DELETE_HKI: 'ADMIN_REQUEST_DELETE_HKI',
    ADMIN_ACTION_HKI_SUCCESS: 'ADMIN_ACTION_HKI_SUCCESS',
    ADMIN_ACTION_HKI_ERROR: 'ADMIN_ACTION_HKI_ERROR',
    ADMIN_RESET_HKI: 'ADMIN_RESET_HKI'
  },
  community: {
    ADMIN_REQUEST_FETCH_COMMUNITYS: 'ADMIN_REQUEST_FETCH_COMMUNITYS',
    ADMIN_REQUEST_CREATE_COMMUNITY: 'ADMIN_REQUEST_CREATE_COMMUNITY',
    ADMIN_REQUEST_UPDATE_COMMUNITY: 'ADMIN_REQUEST_UPDATE_COMMUNITY',
    ADMIN_REQUEST_DELETE_COMMUNITY: 'ADMIN_REQUEST_DELETE_COMMUNITY',
    ADMIN_ACTION_COMMUNITY_SUCCESS: 'ADMIN_ACTION_COMMUNITY_SUCCESS',
    ADMIN_ACTION_COMMUNITY_ERROR: 'ADMIN_ACTION_COMMUNITY_ERROR',
    ADMIN_RESET_COMMUNITY: 'ADMIN_RESET_COMMUNITY'
  },
  support: {
    ADMIN_REQUEST_FETCH_SUPPORTS: 'ADMIN_REQUEST_FETCH_SUPPORTS',
    ADMIN_REQUEST_CREATE_SUPPORT: 'ADMIN_REQUEST_CREATE_SUPPORT',
    ADMIN_REQUEST_UPDATE_SUPPORT: 'ADMIN_REQUEST_UPDATE_SUPPORT',
    ADMIN_REQUEST_DELETE_SUPPORT: 'ADMIN_REQUEST_DELETE_SUPPORT',
    ADMIN_ACTION_SUPPORT_SUCCESS: 'ADMIN_ACTION_SUPPORT_SUCCESS',
    ADMIN_ACTION_SUPPORT_ERROR: 'ADMIN_ACTION_SUPPORT_ERROR',
    ADMIN_RESET_SUPPORT: 'ADMIN_RESET_SUPPORT'
  }
}
