import { call, put } from 'redux-saga/effects'

import { Admin as AdminTypes } from '../../types'
import { Article as ArticleApi } from '../../api/admin'

const {
  ADMIN_REQUEST_FETCH_ARTICLES,
  ADMIN_ACTION_ARTICLE_SUCCESS,
  ADMIN_ACTION_ARTICLE_ERROR
} = AdminTypes.article

function* fetchArticles() {
  try {
    const response = yield call(ArticleApi.fetchArticles)
    yield put({
      type: ADMIN_ACTION_ARTICLE_SUCCESS,
      payload: {
        articles: response.data
      }
    })
  } catch (err) {
      yield put({
        type: ADMIN_ACTION_ARTICLE_ERROR,
        payload: {
          message: err.message
        }
      })
    }
}

function* createArticle({ payload }) {
  try {
    yield call(ArticleApi.createArticle, payload.article)
    yield put({
      type: ADMIN_REQUEST_FETCH_ARTICLES
    })
  } catch (err) {
      yield put({
        type: ADMIN_ACTION_ARTICLE_ERROR,
        payload: {
          message: err.message
        }
      })
    }
}

function* updateArticle({ payload }) {
  try {
    yield call(ArticleApi.updateArticle, payload.article)
    yield put({
      type: ADMIN_REQUEST_FETCH_ARTICLES
    })
  } catch (err) {
      yield put({
        type: ADMIN_ACTION_ARTICLE_ERROR,
        payload: {
          message: err.message
        }
      })
    }
}

function* deleteArticle({ payload }) {
  try {
    yield call(ArticleApi.deleteArticle, payload.id)
    yield put({
      type: ADMIN_REQUEST_FETCH_ARTICLES
    })
  } catch (err) {
    yield put({
      type: ADMIN_ACTION_ARTICLE_ERROR,
      payload: {
        message: err.message
      }
    })
  }
}

export default { fetchArticles, createArticle, updateArticle, deleteArticle }
