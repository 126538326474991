import React, { Component, Fragment } from 'react'
import { withRouter, Link } from 'react-router-dom'
import { compose } from 'redux'
import { connect } from 'react-redux'
import {
  Grid,
  Typography,
  Button,
  LinearProgress
} from '@material-ui/core'
import {
  ChevronLeft as ChevronLeftIcon
} from '@material-ui/icons'
import {
  MainTitle
} from '../components'
import ExceptionRouter from '../routes/exceptions'
import { fetchArticle } from '../store/actions/SelectedArticle'
import env from '../config/env'

const { uri: proxy } = env.server

class Article extends Component {
  constructor(props) {
    super(props)
    this.state = {
      article_id: props.match.params.article_id,
    }
  }

  componentDidMount() {
    const { article_id } = this.state
    this.props.fetchArticle(article_id)
  }

  render() {
    window.scrollTo(0,0)
    const { article, isLoading, errors } = this.props.SelectedArticle

    if (errors.status) {
      return <ExceptionRouter
        message={'Article not found'}
        withDrawer
        />
    }

    return(
      <div className='article'>
        <MainTitle title='Artikel dan Berita' />
        {
          isLoading
          ? <LinearProgress className='linear-progress' color='secondary'/>
          : <Fragment>
              <Grid container className='header'>
                <Grid item lg={8} xs={12} className='thumbnail'>
                  <img alt={article.title} src={`${proxy}/${article.image}`} />
                </Grid>
                <Grid item lg={4} xs={12} className='text'>
                  <Typography variant='h5' className='title'>
                    {article.title}
                  </Typography>
                  <Typography variant='subtitle2' className='date'>
                    {article.createdAt}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container className='body'>
                <Grid item lg={8} xs={12} className='content'>
                  <div className='text_wrapper'>
                    <Typography variant='body1' className='text'>
                      <div dangerouslySetInnerHTML={{__html: article.body}} />
                    </Typography>
                  </div>
                  <div className='footer_wrapper'>
                    <Link to='/' className='link'>
                      <Button size='large' variant='outlined' color='secondary'>
                        <ChevronLeftIcon />{'Kembali'}
                      </Button>
                    </Link>
                  </div>
                </Grid>
              </Grid>
            </Fragment>
        }
      </div>
    )
  }
}

const mapStateToProps = ({ SelectedArticle }) => ({
  SelectedArticle: SelectedArticle
})

export default compose(
  withRouter,
  connect(mapStateToProps, { fetchArticle })
)(Article)
