import { call, put } from 'redux-saga/effects'

import { Support as SupportTypes } from '../types'
import { Support as SupportApi } from '../api'

const {
  FETCH_SUPPORTS_ERROR,
  FETCH_SUPPORTS_SUCCESS
} = SupportTypes

export default function* () {
  try {
    const response = yield call(SupportApi.fetchSupports)
    yield put({
      type: FETCH_SUPPORTS_SUCCESS,
      payload: {
        supports: response.data
      }
    })
  } catch (err) {
    yield put({
      type: FETCH_SUPPORTS_ERROR,
      payload: {
        message: err.message
      }
    })
  }
}
