import { Admin as AdminTypes } from '../../types'

const {
  ADMIN_REQUEST_FETCH_COMMUNITYS,
  ADMIN_REQUEST_CREATE_COMMUNITY,
  ADMIN_REQUEST_UPDATE_COMMUNITY,
  ADMIN_REQUEST_DELETE_COMMUNITY,
  ADMIN_RESET_COMMUNITY
} = AdminTypes.community

export const fetchCommunitys = () => {
  return {
    type: ADMIN_REQUEST_FETCH_COMMUNITYS
  }
}

export const createCommunity = community => {
  return {
    type: ADMIN_REQUEST_CREATE_COMMUNITY,
    payload: { community }
  }
}

export const updateCommunity = community => {
  return {
    type: ADMIN_REQUEST_UPDATE_COMMUNITY,
    payload: { community }
  }
}

export const deleteCommunity = id => {
  return {
    type: ADMIN_REQUEST_DELETE_COMMUNITY,
    payload: { id }
  }
}

export const resetCommunity = () => {
  return {
    type: ADMIN_RESET_COMMUNITY
  }
}
