import { put, call } from 'redux-saga/effects'

import { Paper as PaperTypes } from '../types'
import { Paper as PaperApi } from '../api'

const {
  FETCH_PAPERS_ERROR,
  FETCH_PAPERS_SUCCESS,
  FETCH_RESEARCHS_SUCCESS,
  FETCH_BOOKS_SUCCESS,
  FETCH_HKIS_SUCCESS
} = PaperTypes

export default function* paperWorker() {
  try {
    const response_paper = yield call(PaperApi.fetchPapers)
    yield put({
      type: FETCH_PAPERS_SUCCESS,
      payload: {
        papers: response_paper.data
      }
    })
    const response_research = yield call(PaperApi.fetchResearchs)
    yield put({
      type: FETCH_RESEARCHS_SUCCESS,
      payload: {
        researchs: response_research.data
      }
    })
    const response_book = yield call(PaperApi.fetchBooks)
    yield put({
      type: FETCH_BOOKS_SUCCESS,
      payload: {
        books: response_book.data
      }
    })
    const response_hki = yield call(PaperApi.fetchHkis)
    yield put({
      type: FETCH_HKIS_SUCCESS,
      payload: {
        hkis: response_hki.data
      }
    })
  } catch (err) {
    yield put({
      type: FETCH_PAPERS_ERROR,
      payload: {
        message: err.message
      }
    })
  }
}
