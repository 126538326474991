import { Admin as AdminTypes } from '../../types'

const {
  ADMIN_REQUEST_FETCH_PAPERS,
  ADMIN_REQUEST_CREATE_PAPER,
  ADMIN_REQUEST_UPDATE_PAPER,
  ADMIN_REQUEST_DELETE_PAPER,
  ADMIN_ACTION_PAPER_SUCCESS,
  ADMIN_ACTION_PAPER_ERROR,
  ADMIN_RESET_PAPER
} = AdminTypes.paper

const initialState = {
  papers: [],
  isLoading: false,
  errors: {
    status: false,
    message: null
  }
}

export default (state = initialState, action) => {
  switch (action.type) {

    case ADMIN_ACTION_PAPER_SUCCESS:
      return {
        ...state,
        papers: action.payload.papers,
        isLoading: false,
        errors: {
          ...state.errors,
          status: false,
          message: null
        }
      }

    case ADMIN_ACTION_PAPER_ERROR:
      return {
        ...state,
        isLoading: false,
        errors: {
          ...state.errors,
          status: true,
          message: action.payload.message
        }
      }

    case ADMIN_REQUEST_FETCH_PAPERS:
    case ADMIN_REQUEST_CREATE_PAPER:
    case ADMIN_REQUEST_UPDATE_PAPER:
    case ADMIN_REQUEST_DELETE_PAPER:
      return {
        ...state,
        isLoading: true,
        errors: {
          ...state.errors,
          status: false,
          message: null
        }
      }

    case ADMIN_RESET_PAPER:
      return {
        ...state,
        isLoading: false,
        errors: {
          ...state.errors,
          status: false,
          message: null
        }
      }

    default:
      return state

  }
}
