import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import {
  Button
} from '@material-ui/core'

class Exception extends Component {
  constructor(props) {
    super(props)

    this.state = {
      status: props.status || 404,
      message: props.message || 'Page not found',
      withDrawer: props.withDrawer || false
    }
  }

  render() {
    const { status, message, withDrawer } = this.state

    return (
      <div className={`exception ${withDrawer ? 'with-drawer' : null}`}>
        <p className='title'>{'OOPS!'}</p>
        <p className='subtitle'>{`Error ${status}: ${message}`}</p>
        <Link to='/' className='link'>
          <Button variant='outlined' color='secondary'>
            {'GO BACK'}
          </Button>
        </Link>
      </div>
    )
  }
}

export default Exception
