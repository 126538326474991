import {
  createStore,
  applyMiddleware,
  compose
} from 'redux'
import {
  persistStore,
  persistReducer
} from 'redux-persist'
import createSagaMiddleware from 'redux-saga'
import storage from 'redux-persist/lib/storage'
import rootReducer from './reducers'
import sagas from './sagas'

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['Auth']
}

const persistReducers = persistReducer(
  persistConfig,
  rootReducer
)

const sagaMiddleware = createSagaMiddleware()

export const store = createStore(
  persistReducers,
  compose(
    applyMiddleware(sagaMiddleware)// ,
    // window.__REDUX_DEVTOOLS_EXTENSION__ &&
    // window.__REDUX_DEVTOOLS_EXTENSION__()
  )
)

sagaMiddleware.run(sagas)

export const persistor = persistStore(store);
