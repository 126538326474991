import { Admin as AdminTypes } from '../../types'

const {
  ADMIN_REQUEST_FETCH_ARTICLES,
  ADMIN_REQUEST_CREATE_ARTICLE,
  ADMIN_REQUEST_UPDATE_ARTICLE,
  ADMIN_REQUEST_DELETE_ARTICLE,
  ADMIN_RESET_ARTICLE
} = AdminTypes.article

export const fetchArticles = () => {
  return {
    type: ADMIN_REQUEST_FETCH_ARTICLES
  }
}

export const createArticle = article => {
  return {
    type: ADMIN_REQUEST_CREATE_ARTICLE,
    payload: { article }
  }
}

export const updateArticle = article => {
  return {
    type: ADMIN_REQUEST_UPDATE_ARTICLE,
    payload: { article }
  }
}

export const deleteArticle = id => {
  return {
    type: ADMIN_REQUEST_DELETE_ARTICLE,
    payload: { id }
  }
}

export const resetArticle = () => {
  return {
    type: ADMIN_RESET_ARTICLE
  }
}
