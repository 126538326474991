import { Admin as AdminTypes } from '../../types'

const {
  ADMIN_REQUEST_FETCH_BOOKS,
  ADMIN_REQUEST_CREATE_BOOK,
  ADMIN_REQUEST_UPDATE_BOOK,
  ADMIN_REQUEST_DELETE_BOOK,
  ADMIN_RESET_BOOK
} = AdminTypes.book

export const fetchBooks = () => {
  return {
    type: ADMIN_REQUEST_FETCH_BOOKS
  }
}

export const createBook = book => {
  return {
    type: ADMIN_REQUEST_CREATE_BOOK,
    payload: { book }
  }
}

export const updateBook = book => {
  return {
    type: ADMIN_REQUEST_UPDATE_BOOK,
    payload: { book }
  }
}

export const deleteBook = id => {
  return {
    type: ADMIN_REQUEST_DELETE_BOOK,
    payload: { id }
  }
}

export const resetBook = () => {
  return {
    type: ADMIN_RESET_BOOK
  }
}
