import { Article as ArticleTypes } from '../types'

const {
  REQUEST_FETCH_ARTICLES,
  FETCH_ARTICLES_ERROR,
  FECTH_ARTICLES_SUCCESS,
  RESET_ARTICLE
} = ArticleTypes

const initialState = {
  articles: [],
  limit: 6,
  offset: 0,
  isMax: false,
  isLoading: false,
  errors: {
    status: false,
    message: null
  }
}

export default (state = initialState, action) => {
  switch (action.type) {

    case REQUEST_FETCH_ARTICLES:
      return {
        ...state,
        isLoading: true,
        errors: {
          ...state.errors,
          status: false,
          message: null
        }
      }

    case FECTH_ARTICLES_SUCCESS:
      return {
        ...state,
        articles: [...state.articles, ...action.payload.articles],
        offset: state.offset + action.payload.articles.length,
        isMax: state.articles.length + action.payload.articles.length >= action.payload.count,
        isLoading: false,
        errors: {
          ...state.errors,
          status: false,
          message: null
        }
      }

    case FETCH_ARTICLES_ERROR:
      return {
        ...state,
        isLoading: false,
        errors: {
          ...state.errors,
          status: true,
          message: action.payload.message
        }
      }

    case RESET_ARTICLE:
      return {
        ...state,
        isLoading: false,
        errors: {
          ...state.errors,
          status: false,
          message: null
        }
      }

    default:
      return state

  }
}
