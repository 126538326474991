import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import {
  Grid,
  ButtonBase
} from '@material-ui/core'
import env from '../config/env'

const { uri: proxy } = env.server

class ArticleCard extends Component {
  render() {
    const { articles } = this.props
    return(
      <Grid container spacing={2}>
      {
        articles.map((article,i) => (
          <Grid item xs={12} sm={6} lg={4} key={i} className='article_card'>
            <Link to={`/article/${article._id}`} className='link'>
              <ButtonBase style={{width: '100%'}} color='secondary'>
                <div className='media'>
                  <img src={`${proxy}/${article.image}`} alt={article.title} className='thumbnail'/>
                </div>
              </ButtonBase>
              <p className='title'>{article.title}</p>
            </Link>
            <p className='date'>{article.createdAt}</p>
          </Grid>
        ))
      }
      </Grid>
    )
  }
}

export default ArticleCard
