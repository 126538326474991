import axios from 'axios'

import dateForHuman from '../../utils/date-for-human'
import env from '../../config/env'

const { uri: proxy } = env.server

const fetchArticle = id => {
  return new Promise((resolve, reject) => {
    axios.get(`${proxy}/api/article/${id}`)
      .then(response => {
        let article = response.data.data
        article.createdAt = dateForHuman(article.createdAt)
        article.updatedAt = dateForHuman(article.updatedAt)
        resolve(response.data)
      })
      .catch(err => {
        const defaultError = { message: 'Check your internet connection' }
        const { response = { } } = err
        const { data = defaultError } = response
        reject(data)
      })
  })
}

export default { fetchArticle }
