import React, { Component } from 'react'
import { Provider } from 'react-redux'
import { store, persistor } from './store'
import { PersistGate } from 'redux-persist/integration/react'
import Router from './Router'

import env from './config/env'

class App extends Component {
  componentDidMount() {
    document.title = env.profile.nama
  }

  render() {
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Router />
        </PersistGate>
      </Provider>
    )
  }
}

export default App
