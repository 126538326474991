import { call, put } from 'redux-saga/effects'

import { Admin as AdminTypes } from '../../types'
import { Book as BookApi } from '../../api/admin'

const {
  ADMIN_REQUEST_FETCH_BOOKS,
  ADMIN_ACTION_BOOK_SUCCESS,
  ADMIN_ACTION_BOOK_ERROR
} = AdminTypes.book

function* fetchBooks() {
  try {
    const response = yield call(BookApi.fetchBooks)
    yield put({
      type: ADMIN_ACTION_BOOK_SUCCESS,
      payload: {
        books: response.data
      }
    })
  } catch (err) {
    yield put({
      type: ADMIN_ACTION_BOOK_ERROR,
      payload: {
        message: err.message
      }
    })
  }
}

function* createBook({ payload }) {
  try {
    yield call(BookApi.createBook, payload.book)
    yield put({
      type: ADMIN_REQUEST_FETCH_BOOKS
    })
  } catch (err) {
    yield put({
      type: ADMIN_ACTION_BOOK_ERROR,
      payload: {
        message: err.message
      }
    })
  }
}

function* updateBook({ payload }) {
  try {
    yield call(BookApi.updateBook, payload.book)
    yield put({
      type: ADMIN_REQUEST_FETCH_BOOKS
    })
  } catch (err) {
    yield put({
      type: ADMIN_ACTION_BOOK_ERROR,
      payload: {
        message: err.message
      }
    })
  }
}

function* deleteBook({ payload }) {
  try {
    yield call(BookApi.deleteBook, payload.id)
    yield put({
      type: ADMIN_REQUEST_FETCH_BOOKS
    })
  } catch (err) {
    yield put({
      type: ADMIN_ACTION_BOOK_ERROR,
      payload: {
        message: err.message
      }
    })
  }
}

export default { fetchBooks, createBook, updateBook, deleteBook }
