import { Admin as AdminTypes } from '../../types'

const {
  ADMIN_REQUEST_FETCH_PAPERS,
  ADMIN_REQUEST_CREATE_PAPER,
  ADMIN_REQUEST_UPDATE_PAPER,
  ADMIN_REQUEST_DELETE_PAPER,
  ADMIN_RESET_PAPER
} = AdminTypes.paper

export const fetchPapers = () => {
  return {
    type: ADMIN_REQUEST_FETCH_PAPERS
  }
}

export const createPaper = paper => {
  return {
    type: ADMIN_REQUEST_CREATE_PAPER,
    payload: { paper }
  }
}

export const updatePaper = paper => {
  return {
    type: ADMIN_REQUEST_UPDATE_PAPER,
    payload: { paper }
  }
}

export const deletePaper = id => {
  return {
    type: ADMIN_REQUEST_DELETE_PAPER,
    payload: { id }
  }
}

export const resetPaper = () => {
  return {
    type: ADMIN_RESET_PAPER
  }
}
