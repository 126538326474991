import { call, put } from 'redux-saga/effects'

import { Admin as AdminTypes } from '../../types'
import { Support as SupportApi } from '../../api/admin'

const {
  ADMIN_REQUEST_FETCH_SUPPORTS,
  ADMIN_ACTION_SUPPORT_SUCCESS,
  ADMIN_ACTION_SUPPORT_ERROR
} = AdminTypes.support

function* fetchSupports() {
  try {
    const response = yield call(SupportApi.fetchSupports)
    yield put({
      type: ADMIN_ACTION_SUPPORT_SUCCESS,
      payload: {
        supports: response.data
      }
    })
  } catch (err) {
    yield put({
      type: ADMIN_ACTION_SUPPORT_ERROR,
      payload: {
        message: err.message
      }
    })
  }
}

function* createSupport({ payload }) {
  try {
    yield call(SupportApi.createSupport, payload.support)
    yield put({
      type: ADMIN_REQUEST_FETCH_SUPPORTS
    })
  } catch (err) {
    yield put({
      type: ADMIN_ACTION_SUPPORT_ERROR,
      payload: {
        message: err.message
      }
    })
  }
}

function* updateSupport({ payload }) {
  try {
    yield call(SupportApi.updateSupport, payload.support)
    yield put({
      type: ADMIN_REQUEST_FETCH_SUPPORTS
    })
  } catch (err) {
    yield put({
      type: ADMIN_ACTION_SUPPORT_ERROR,
      payload: {
        message: err.message
      }
    })
  }
}

function* deleteSupport({ payload }) {
  try {
    yield call(SupportApi.deleteSupport, payload.id)
    yield put({
      type: ADMIN_REQUEST_FETCH_SUPPORTS
    })
  } catch (err) {
    yield put({
      type: ADMIN_ACTION_SUPPORT_ERROR,
      payload: {
        message: err.message
      }
    })
  }
}

export default { fetchSupports, createSupport, updateSupport, deleteSupport }
