import axios from 'axios'

import dateForHuman from '../../utils/date-for-human'
import env from '../../config/env'

const { uri: proxy } = env.server

const fetchArticles = offset => {
  const options = {
    limit: 6,
    offset: offset
  }
  return new Promise((resolve, reject) => {
    axios.get(`${proxy}/api/article`, {
      data: { ...options }
    })
      .then(response => {
        let { data: articles } = response.data
        articles = articles.map(article => ({
          ...article,
          createdAt: dateForHuman(article.createdAt),
          updatedAt: dateForHuman(article.updatedAt)
        }))
        response.data.data = articles
        resolve(response.data)
      })
      .catch(err => {
        const defaultError = { message: 'Check your internet connection' }
        const { response = { } } = err
        const { data = defaultError } = response
        reject(data)
      })
  })
}

export default { fetchArticles }
