import { Auth as AuthTypes } from '../types'

const {
  REQUEST_LOGIN,
  RESET_AUTH,
  LOGOUT
} = AuthTypes

export const requestLogin = payload => {
  return {
    type: REQUEST_LOGIN,
    payload
  }
}

export const logout = () => {
  return {
    type: LOGOUT
  }
}

export const resetAuth = () => {
  return {
    type: RESET_AUTH
  }
}
