import { put, call } from 'redux-saga/effects'

import { SelectedArticle as SelectedArticleTypes } from '../types'
import { SelectedArticle as SelectedArticleApi } from '../api'

const {
  FETCH_SELECTED_ARTICLE_SUCCESS,
  FETCH_SELECTED_ARTICLE_ERROR
} = SelectedArticleTypes

export default function* selectedArticleWorker({ payload }) {
  try {
    const response = yield call(SelectedArticleApi.fetchArticle, payload.id)
    yield put({
      type: FETCH_SELECTED_ARTICLE_SUCCESS,
      payload: {
        article: response.data
      }
    })
  } catch (err) {
    yield put({
      type: FETCH_SELECTED_ARTICLE_ERROR,
      payload: {
        message: err.message
      }
    })
  }
}
