import { SelectedArticle as SelectedArticleTypes } from '../types'

const {
  REQUEST_FETCH_SELECTED_ARTICLE,
  FETCH_SELECTED_ARTICLE_SUCCESS,
  FETCH_SELECTED_ARTICLE_ERROR
} = SelectedArticleTypes

const initialState = {
  article: {},
  isLoading: false,
  errors: {
    status: false,
    message: null
  }
}

export default (state = initialState, action) => {
  switch (action.type) {

    case REQUEST_FETCH_SELECTED_ARTICLE:
      return {
        ...state,
        isLoading: true,
        errors: {
          status: false,
          message: null
        }
      }

    case FETCH_SELECTED_ARTICLE_SUCCESS:
      return {
        ...state,
        article: action.payload.article,
        isLoading: false,
        errors: {
          ...state.errors,
          status: false,
          message: null
        }
      }

    case FETCH_SELECTED_ARTICLE_ERROR:
      return {
        ...state,
        isLoading: false,
        errors: {
          ...state.errors,
          status: true,
          message: action.payload.message
        }
      }

    default:
      return state

  }
}
