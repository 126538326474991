import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  Button
} from '@material-ui/core'
import {
  MainTitle,
  TableComponent,
} from '../components'
import { fetchSupports, resetSupport } from '../store/actions/Support'
import env from '../config/env'
import { Snackbar as MySnackbar } from '../components'

const { uri: proxy } = env.server

class Support extends Component {
  componentDidMount() {
    this.props.fetchSupports()
    this.resetError()
  }

  resetError = () => {
    this.props.resetSupport()
  }

  render() {
    let { supports } = this.props.Support
    const { isLoading, errors } = this.props.Support

    supports = supports.map(support => ({
      ...support,
      file: (<Button
        color='secondary'
        variant='outlined'
        target='_blank'
        href={`${proxy}/${support.file}`}
        >{'UNDUH'}</Button>)
    }))

    const schema = {
      year: 'Tahun',
      type: 'Tipe',
      place: 'Tempat',
      file: 'Berkas'
    }

    const title = 'Penunjang'

    return(
      <div>
        <MySnackbar
          open={errors.status}
          message={errors.message}
          variant={'error'}
          handleClose={this.resetError}
          />
        <MainTitle title={title}/>
        <TableComponent
          loading={isLoading}
          data={supports}
          schema={schema}
          title={'Penunjang'}
          />
      </div>
    )
  }
}

const mapStateToProps = ({ Support }) => ({
  Support: Support
})

export default connect(mapStateToProps, { fetchSupports, resetSupport })(Support)
