import React, { useState, Fragment } from 'react'
import {
  ExpandMore as ExpandIcon
} from '@material-ui/icons'
import {
  Table,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  CircularProgress
} from '@material-ui/core'

export default props => {
  const { data, schema, title, expanded, loading, headerNode } = props
  const [open, toggleExpanded] = useState(expanded)

  const isEmpty = data.length <= 0

  return (
    <ExpansionPanel className='expansion_panel' expanded={open}>
      <ExpansionPanelSummary
        onClick={() => toggleExpanded(!open)}
        expandIcon={(<ExpandIcon />)}
        className='summary'>
        {title || '-'}
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className='details'>
        {
          loading
          ? <div className='loading'>
              <CircularProgress color='secondary' size={30}/>
            </div>
          : <div className='table_component'>
              <div>
                {
                  headerNode
                  ? <Fragment>
                      {headerNode}
                      <br /> <br /> <br />
                    </Fragment>
                  : null
                }
                {
                  isEmpty
                  ? <span className='empty-data'>No records to display</span>
                  : <Table>
                      <TableHead className='thead'>
                        <TableRow>
                          {
                            Object.keys(schema).map((key,i) => (
                              <TableCell className='cell' key={i}>{schema[key]}</TableCell>
                            ))
                          }
                        </TableRow>
                      </TableHead>
                      <TableBody className='tbody'>
                        {
                          data.map((data,i) => (
                            <TableRow key={i}>
                              {
                                Object.keys(schema).map((key,i) => (
                                  <TableCell className='cell' key={i}>{data[key]}</TableCell>
                                ))
                              }
                            </TableRow>
                          ))
                        }
                      </TableBody>
                    </Table>
                  }
              </div>
            </div>
        }
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}
