import React, { Component, Fragment } from 'react'
import {
  BrowserRouter,
  Route,
  Switch
} from 'react-router-dom'
import {
  Academic as AcademicRouter,
  Community as CommunityRouter,
  Home as HomeRouter,
  Profile as ProfileRouter,
  Research as ResearchRouter,
  Article as ArticleRouter,
  Support as SupportRouter
} from './routes'
import {
  Login as LoginAdminRouter,
  Home as HomeAdminRouter,
  Article as ArticleAdminRouter,
  Research as ResearchAdminRouter,
  Support as SupportAdminRouter,
  Community as CommunityAdminRouter
} from './routes/admin'
import ExceptionRouter from './routes/exceptions'
import {
  HomeOutlined as HomeIcon,
  SchoolOutlined as AcademicIcon,
  PersonOutlined as ProfileIcon,
  GroupOutlined as CommunityIcon,
  WorkOutline as ResearchIcon,
  LibraryBooksOutlined as ArticleIcon,
  MoreHorizRounded as SupportIcon,
} from '@material-ui/icons'
import {
  Drawer as MyDrawer
} from './components'
import './assets/styles/styles.sass'

const routes = [
  {
    name: 'Beranda',
    path: '/',
    exact: true,
    component: HomeRouter,
    icon: HomeIcon,
    list: true
  },
  {
    name: 'Artikel',
    path: '/article/:article_id',
    exact: true,
    component: ArticleRouter,
    icon: HomeIcon,
    list: false
  },
  {
    name: 'Profil',
    path: '/profile',
    exact: true,
    component: ProfileRouter,
    icon: ProfileIcon,
    list: true
  },
  {
    name: 'Akademik',
    path: '/academic',
    exact: true,
    component: AcademicRouter,
    icon: AcademicIcon,
    list: true
  },
  {
    name: 'Pengabdian Masyarakat',
    path: '/community',
    exact: true,
    component: CommunityRouter,
    icon: CommunityIcon,
    list: true
  },
  {
    name: 'Penelitian',
    path: '/research',
    exact: true,
    component: ResearchRouter,
    icon: ResearchIcon,
    list: true
  },
  {
    name: 'Penunjang',
    path: '/support',
    exact: true,
    component: SupportRouter,
    icon: SupportIcon,
    list: true
  }
]

const adminRoutes = [
  {
    name: 'Home',
    path: '/admin/dashboard',
    exact: true,
    component: HomeAdminRouter,
    icon: HomeIcon,
    list: true
  },
  {
    name: 'Article',
    path: '/admin/dashboard/article',
    exact: true,
    component: ArticleAdminRouter,
    icon: ArticleIcon,
    list: true
  },
  {
    name: 'Reseach',
    path: '/admin/dashboard/research',
    exact: true,
    component: ResearchAdminRouter,
    icon: ResearchIcon,
    list: true
  },
  {
    name: 'Pengabdian Masyarakat',
    path: '/admin/dashboard/community',
    exact: true,
    component: CommunityAdminRouter,
    icon: CommunityIcon,
    list: true
  },
  {
    name: 'Penunjang',
    path: '/admin/dashboard/suppory',
    exact: true,
    component: SupportAdminRouter,
    icon: SupportIcon,
    list: true
  }
]

class Router extends Component {
  render() {
    return(
      <BrowserRouter>
        <Switch>
          {
            routes.map((route, i) => (
              <Route
                key={i}
                path={route.path}
                exact>
                <Fragment>
                  <nav>
                    <MyDrawer routes={routes}/>
                  </nav>
                  <main>
                    <route.component />
                  </main>
                </Fragment>
              </Route>
            ))
          }
          <Route path='/admin/login' exact component={LoginAdminRouter}></Route>
          {
            adminRoutes.map((route, i) => (
              <Route
                key={i}
                path={route.path}
                exact>
                <Fragment>
                  <nav>
                    <MyDrawer routes={adminRoutes} logoutButton/>
                  </nav>
                  <main>
                    <route.component />
                  </main>
                </Fragment>
              </Route>
            ))
          }
          <Route component={() => <ExceptionRouter/>}/>
        </Switch>
      </BrowserRouter>
    )
  }
}

export default Router
