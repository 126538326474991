import React, { Component, Fragment } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Redirect, withRouter } from 'react-router-dom'
import { requestLogin, resetAuth } from '../../store/actions/Auth'
import {
  Typography,
  TextField,
  Button,
  Avatar,
  CircularProgress
} from '@material-ui/core'
import {
  Snackbar as MySnackbar
} from '../../components'
import AvatarImage from '../../assets/images/avatar.jpg'

class Login extends Component {
  constructor(props) {
    super(props)

    this.state = {
      input: {
        email: '',
        password: ''
      }
    }
  }

  componentWillMount() {
    this.resetError()
  }

  resetError = () => {
    this.props.resetAuth()
  }

  onChangeInput = e => {
    const { input } = this.state
    this.setState({
      input: {
        ...input,
        [e.target.id]: e.target.value
      }
    })
  }

  onSubmitForm = e => {
    e.preventDefault()
    const { input: data } = this.state
    this.onLogin(data)
  }

  onLogin = async data => {
    this.props.requestLogin(data)
  }

  render() {
    const { input } = this.state
    const { token, isLoading, errors } = this.props.Auth

    return (
      <Fragment>
        <MySnackbar
          open={errors.status}
          handleClose={this.resetError}
          message={errors.message}
          variant={'error'}
          />
          {
            token === null
            ? <div className='login'>
              <div className='header'>
                <Typography
                  variant='h6'
                  className='text'>
                  Welcome
                </Typography>
              </div>
              <form onSubmit={this.onSubmitForm}>
                <div className='body'>
                  <Avatar src={AvatarImage} alt='Avatar' className='avatar'/>
                  <TextField
                    id='email'
                    className='input-field'
                    color='secondary'
                    label='Email'
                    type='text'
                    value={input.email}
                    onChange={this.onChangeInput}
                    />
                  <TextField
                    id='password'
                    className='input-field'
                    color='secondary'
                    label='Password'
                    type='password'
                    value={input.password}
                    onChange={this.onChangeInput}
                    />
                </div>
                <div className='footer'>
                  <Button
                    className='button'
                    variant='contained'
                    color='secondary'
                    type='submit'
                    disabled={isLoading}
                    >
                    {
                      isLoading
                      ? (<CircularProgress size={24} color='secondary'/>)
                      : 'LOGIN'
                    }
                  </Button>
                </div>
              </form>
            </div>
            : <Redirect to='/admin/dashboard' />
          }
      </Fragment>
    )
  }
}

const mapStateToProps = ({ Auth }) => ({
  Auth: Auth
})

export default compose(
  connect(mapStateToProps, { requestLogin, resetAuth }),
  withRouter
)(Login)
