export default dateString => {
  const ISODate = new Date(dateString)
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]

  const date = ISODate.getDate()
  const month = months[ISODate.getMonth()]
  const year = ISODate.getFullYear()

  return `${date} ${month} ${year}`
}
