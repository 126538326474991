import { combineReducers } from 'redux'
import AuthReducer from './Auth'
import ArticleReducer from './Article'
import PaperReducer from './Paper'
import SelectedArticleReducer from './SelectedArticle'
import CommunityReducer from './Community'
import SupportReducer from './Support'
import {
  Paper as AdminPaperReducer,
  Article as AdminArticleReducer,
  Research as AdminResearchReducer,
  Book as AdminBookReducer,
  HKI as AdminHKIReducer,
  Community as AdminCommunityReducer,
  Support as AdminSupportReducer
} from './admin'

export default combineReducers({
  AdminPaper: AdminPaperReducer,
  AdminArticle: AdminArticleReducer,
  AdminResearch: AdminResearchReducer,
  AdminBook: AdminBookReducer,
  AdminHKI: AdminHKIReducer,
  AdminCommunity: AdminCommunityReducer,
  AdminSupport: AdminSupportReducer,
  Auth: AuthReducer,
  Paper: PaperReducer,
  Article: ArticleReducer,
  SelectedArticle: SelectedArticleReducer,
  Community: CommunityReducer,
  Support: SupportReducer
})
